/**
 * DEALER_ADMIN
 */

export default (() => {
  return {
    DEALER_ADMIN: {
      defaultSelectedId: ["Orders"],
      items: [
        {
          id: "Orders",
          name: "Заказы",
          icon: "shopping-cart",
          path: "/orders",
        },
        {
          id: "Refunds",
          name: "Возвраты",
          icon: "shopping-cart",
          children: {
            items: [
              {
                id: "RefundItems",
                name: "Список",
                path: "/refunds",
              },
            ],
          },
        },
        {
          id: "SearchDetails",
          name: "Поиск запчастей",
          icon: "monitor",
          path: "/",
        },
        {
          id: "Clients",
          name: "Клиенты",
          icon: "team",
          roles: ["Dealer"],
          children: {
            items: [
              {
                id: "Clients",
                name: "Список клиентов",
                path: "/clients",
              },
              {
                id: "CheckClientExist",
                name: "Регистрация нового клиента",
                path: "/createclient?name=&inn=",
              },
              // {
              //   id: "Markups",
              //   name: "Программа лояльности",
              //   path: "/markups",
              // },
            ],
          },
        },
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        {
          id: "Prices",
          name: "Прайсы",
          icon: "container",
          children: {
            items: [
              {
                id: "LocalPrices",
                name: "Прайс-листы",
                path: "/prices",
              },
              /* {
                id: "ClientPrices",
                name: "Клиентские прайс-листы",
                path: "/clientprices",
              },*/
              // {
              //   id: "PricesCS",
              //   name: "Прайс-листы ЦС",
              //   path: "/pricescs",
              // },
              {
                id: "PaymentGroups",
                name: "Группы опта",
                path: "/paymentgroups",
              },
              // {
              //   id: "PricesDelivery",
              //   name: "Рассылка прайс-листов",
              //   path: "/pricesdelivery",
              // },
              // {
              //   id: "DirectoriesCS",
              //   name: "Справочники ЦС",
              //   path: "/directoriescs",
              // },
              /*{
                id: "Directories",
                name: "Локальный справочник",
                path: "/directories",
              },*/
              // {
              //   id: "AllowedBrandsList",
              //   name: "Список разрешенных брендов",
              //   path: "/allowedbrands",
              // },
            ],
          },
        },
        // {
        //   id: "SearchDetails",
        //   name: "Поиск запчастей",
        //   icon: "monitor",
        //   children: {
        //     items: [
        //       {
        //         id: "SearchDetails",
        //         name: "Поиск запчастей",
        //         path: "/",
        //       },
        //       {
        //         id: "Basket",
        //         name: "Корзина",
        //         icon: "shopping-cart",
        //         path: "/basket",
        //       },
        //     ],
        //   },
        // },
        // {
        //   id: "Promotions",
        //   name: "Акции",
        //   icon: "percentage",
        //   path: "/promotions",
        // },
        /*{
          id: "DealerDocuments",
          name: "Документы",
          icon: "file-text",
          path: "/dealer/documents",
        },*/
        // {
        //   id: "Delivery",
        //   name: "Доставка",
        //   icon: "exclamation-circle",
        //   path: "/delivery",
        // },
        {
          id: "CreateUser",
          name: "Пользователи системы",
          icon: "team",
          path: "/createuser",
        },
      ],
    },
  }
})()
