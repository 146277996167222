import axios from "axios"

import errorHandler from "@/utils/errorHandler"
import { API_PREFIX } from "@/services/api/v1/const"

export default class PricesService {
  async getPrices(type = "local") {
    try {
      return await axios.get(`${API_PREFIX}/catalog`, {
        params: {
          XDEBUG_SESSION: "PHPSTORM",
          type,
        },
      })
    } catch (error) {
      errorHandler(error)
    }
  }
  async getCommissions() {
    try {
      return await axios.get(`${API_PREFIX}/commission`)
    } catch (error) {
      errorHandler(error)
    }
  }

  async setCommissions(commissions) {
    try {
      return await axios.post(
        `${API_PREFIX}/commission?XDEBUG_SESSION=PHPSTORM`,
        commissions
      )
    } catch (error) {
      errorHandler(error)
    }
  }

  async getPeriodSettings() {
    try {
      return await axios.get(`${API_PREFIX}/priceimportsettings`)
    } catch (error) {
      errorHandler(error)
    }
  }

  async setPeriodSettings(cron) {
    try {
      return await axios.patch(`${API_PREFIX}/priceimportsettings/1`, {
        cron,
      })
    } catch (error) {
      errorHandler(error)
    }
  }

  async uploadPrice(formData) {
    try {
      return await axios.post(`${API_PREFIX}/import-catalog-file`, formData)
    } catch (error) {
      errorHandler(error)
    }
  }
  async updatePrice(price, id) {
    return axios.patch(`${API_PREFIX}/catalog/${id}`, {
      name: price.name,
      code: price.code,
      type: price.type,
      deliveryPeriod: Number(price.deliveryPeriod),
      sharing: price.sharing,
      priceType: price.priceType,
      status: price.status,
      id: price.id,
      createdAt: price.createdAt,
    })
  }
  async deletePrice(id) {
    return await axios.delete(`${API_PREFIX}/catalog/${id}`)
  }
}
