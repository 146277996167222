/**
 * IMPORTER_ORDER_OPERATOR
 */

export default (() => {
  return {
    IMPORTER_ORDER_OPERATOR: {
      defaultSelectedId: ["8"],
      items: [
        {
          id: "8",
          icon: "exclamation-circle",
          name: "Дилеры",
          path: "/dealers",
        },
        {
          id: "Clients",
          name: "Клиенты",
          icon: "team",
          path: "/clients",
        },
        {
          id: "Price",
          name: "Прайс-листы",
          icon: "container",
          path: "/prices",
        },
      ],
    },
  }
})()
