import Vue from "vue"
import App from "@/App"
import router from "@/router"
import store from "@/store/store"

import Antd from "ant-design-vue"
import "ant-design-vue/dist/antd.less"

import "./helpers/axios/interceptors"
import mixins from "@/utils/mixins"
import "./plugins/veevalidate"
Vue.use(Antd)

import Vuelidate from "vuelidate"
Vue.use(Vuelidate)

Vue.config.productionTip = false
Vue.mixin({
  filters: mixins.filters,
})
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app")
