import { DummyApiService } from "@/services"
const service = new DummyApiService()

export const state = {
  filters: {
    current: {
      isLineShow: "yes",
      isSumShow: "no",
    },
    temp: {
      isLineShow: "yes",
      isSumShow: "no",
    },
    default: {
      isLineShow: "yes",
      isSumShow: "no",
    },
  },
  line: {
    tables: {
      list: [
        {
          columns: [
            {
              title: "Количество заказанных линий, всего",
              key: "dealer",
              dataIndex: "dealer",
            },
            {
              title: "янв.20",
              key: "january",
              dataIndex: "january",
            },
            {
              title: "фев.20",
              key: "february",
              dataIndex: "february",
            },
            {
              title: "мар.20",
              key: "march",
              dataIndex: "march",
            },
            {
              title: "апр.20",
              key: "april",
              dataIndex: "april",
            },
            {
              title: "май.20",
              key: "may",
              dataIndex: "may",
            },
            {
              title: "июн.20",
              key: "june",
              dataIndex: "june",
            },
            {
              title: "июл.20",
              key: "july",
              dataIndex: "july",
            },
            {
              title: "авг.20",
              key: "august",
              dataIndex: "august",
            },
            {
              title: "сен.20",
              key: "september",
              dataIndex: "september",
            },
            {
              title: "окт.20",
              key: "october",
              dataIndex: "october",
            },
            {
              title: "ноя.20",
              key: "november",
              dataIndex: "november",
            },
          ],
          data: [],
        },
        {
          columns: [
            {
              title: "Количество отказанных линий, всего",
              key: "dealer",
              dataIndex: "dealer",
            },
            {
              title: "янв.20",
              key: "january",
              dataIndex: "january",
            },
            {
              title: "фев.20",
              key: "february",
              dataIndex: "february",
            },
            {
              title: "мар.20",
              key: "march",
              dataIndex: "march",
            },
            {
              title: "апр.20",
              key: "april",
              dataIndex: "april",
            },
            {
              title: "май.20",
              key: "may",
              dataIndex: "may",
            },
            {
              title: "июн.20",
              key: "june",
              dataIndex: "june",
            },
            {
              title: "июл.20",
              key: "july",
              dataIndex: "july",
            },
            {
              title: "авг.20",
              key: "august",
              dataIndex: "august",
            },
            {
              title: "сен.20",
              key: "september",
              dataIndex: "september",
            },
            {
              title: "окт.20",
              key: "october",
              dataIndex: "october",
            },
            {
              title: "ноя.20",
              key: "november",
              dataIndex: "november",
            },
          ],
          data: [],
        },
        {
          columns: [
            {
              title: "Количество отказанных линий, всего",
              key: "dealer",
              dataIndex: "dealer",
            },
            {
              title: "янв.20",
              key: "january",
              dataIndex: "january",
            },
            {
              title: "фев.20",
              key: "february",
              dataIndex: "february",
            },
            {
              title: "мар.20",
              key: "march",
              dataIndex: "march",
            },
            {
              title: "апр.20",
              key: "april",
              dataIndex: "april",
            },
            {
              title: "май.20",
              key: "may",
              dataIndex: "may",
            },
            {
              title: "июн.20",
              key: "june",
              dataIndex: "june",
            },
            {
              title: "июл.20",
              key: "july",
              dataIndex: "july",
            },
            {
              title: "авг.20",
              key: "august",
              dataIndex: "august",
            },
            {
              title: "сен.20",
              key: "september",
              dataIndex: "september",
            },
            {
              title: "окт.20",
              key: "october",
              dataIndex: "october",
            },
            {
              title: "ноя.20",
              key: "november",
              dataIndex: "november",
            },
          ],
          data: [],
        },
        {
          columns: [
            {
              title: "Количество отгруженных линий, всего",
              key: "dealer",
              dataIndex: "dealer",
            },
            {
              title: "янв.20",
              key: "january",
              dataIndex: "january",
            },
            {
              title: "фев.20",
              key: "february",
              dataIndex: "february",
            },
            {
              title: "мар.20",
              key: "march",
              dataIndex: "march",
            },
            {
              title: "апр.20",
              key: "april",
              dataIndex: "april",
            },
            {
              title: "май.20",
              key: "may",
              dataIndex: "may",
            },
            {
              title: "июн.20",
              key: "june",
              dataIndex: "june",
            },
            {
              title: "июл.20",
              key: "july",
              dataIndex: "july",
            },
            {
              title: "авг.20",
              key: "august",
              dataIndex: "august",
            },
            {
              title: "сен.20",
              key: "september",
              dataIndex: "september",
            },
            {
              title: "окт.20",
              key: "october",
              dataIndex: "october",
            },
            {
              title: "ноя.20",
              key: "november",
              dataIndex: "november",
            },
          ],
          data: [],
        },
      ],
      hidden: false,
    },
  },
}

export const mutations = {
  FILTER: state =>
    (state.filters.current = {
      ...state.filters.temp,
    }),
  CLEAR_FILTERS: state => {
    state.filters.current = {
      ...state.filters.default,
    }
    state.filters.temp = {
      ...state.filters.default,
    }
  },
  UPDATE_FILTER_IS_LINE_SHOW: (state, payload) =>
    (state.filters.temp.isLineShow = payload),
  UPDATE_FILTER_IS_SUM_SHOW: (state, payload) =>
    (state.filters.temp.isSumShow = payload),
  TOGGLE_TABLES_VISIBILITY: state =>
    (state.line.tables.hidden = !state.line.tables.hidden),
  SET_TABLES_DATA: (state, payload) => {
    for (let i = 0; i < payload.length; i++) {
      state.line.tables.list[i].data = payload[i]
    }
  },
}

export const actions = {
  fetchTables: async ({ commit }) => {
    const list = await service.getReportDynamicGroupLineData()
    commit("SET_TABLES_DATA", list)
  },
}

export const getters = {
  isLineShow: state => state.filters.current.isLineShow === "yes",
  isTempLineShow: state => state.filters.temp.isLineShow === "yes",
  isSumShow: state => state.filters.current.isSumShow === "yes",
  isTempSumShow: state => state.filters.temp.isSumShow === "yes",

  lineTables: state => state.line.tables.list,
  isShowTables: state => !state.line.tables.hidden,
}
