/**
 * DEALER_ORDER_OPERATOR
 */

export default (() => {
  return {
    DEALER_ORDER_OPERATOR: {
      defaultSelectedId: ["Orders"],
      items: [
        {
          id: "Orders",
          name: "Заказы",
          icon: "shopping-cart",
          path: "/orders",
        },
        {
          id: "SearchDetails",
          name: "Поиск запчастей",
          icon: "monitor",
          path: "/",
        },
        {
          id: "Clients",
          name: "Клиенты",
          icon: "team",
          roles: ["Dealer"],
          children: {
            items: [
              {
                id: "Clients",
                name: "Список клиентов",
                path: "/clients",
              },
              {
                id: "CheckClientExist",
                name: "Регистрация нового клиента",
                path: "/checkclient",
              },
              // {
              //   id: "Markups",
              //   name: "Стандартные наценки",
              //   path: "/markups",
              // },
            ],
          },
        },
        {
          id: "Price",
          name: "Прайс-листы",
          icon: "container",
          path: "/prices",
        },
        // {
        //   id: "Delivery",
        //   name: "Доставка",
        //   icon: "exclamation-circle",
        //   path: "/delivery",
        // },
      ],
    },
  }
})()
