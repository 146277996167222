import PricesImportService from "@/services/api/v1/PricesService"
const pricesService = new PricesImportService()

export const state = {
  loading: false,
  prices: [],
  commissions: [],
}

export const getters = {
  prices: state => state.prices,
  loading: state => state.loading,
  commissions: state => state.commissions,
}

export const mutations = {
  SET_PRICES(state, payload) {
    state.prices = payload
  },
  SET_COMMISSIONS(state, payload) {
    state.commissions = payload
  },
  SET_CREATE_FORM_PRICES(state, payload) {
    const idx = state.prices.findIndex(el => el.id === payload.index)
    state.prices[idx][payload.name] = payload.value
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
}

export const actions = {
  async fetchPrices({ commit }, arg) {
    commit("SET_LOADING", true)

    let type = "cw"
    if (arg) type = arg
    const prices = await pricesService.getPrices(type)

    commit("SET_LOADING", false)
    if (!prices?.data) return []

    commit("SET_PRICES", prices.data.items)
    return prices.data.items ?? []
  },
  clearPrices({ commit }) {
    commit("SET_PRICES", [])
  },
  async getCommissions({ commit }) {
    const commissions = await pricesService.getCommissions()

    if (!commissions?.data) return

    commit("SET_COMMISSIONS", commissions.data)
    return commissions.data
  },
  clearCommissions({ commit }) {
    commit("SET_COMMISSIONS", [])
  },
  async setCommissions(_, commissions) {
    return await pricesService.setCommissions(commissions)
  },
  async getPeriodSettings() {
    return await pricesService.getPeriodSettings()
  },
  async setPeriodSettings(_, cron) {
    return await pricesService.setPeriodSettings(cron)
  },
  async uploadPrice(_, price) {
    console.info("upload")
    return await pricesService.uploadPrice(price)
  },
  async updatePrices({ getters }, id) {
    const idx = getters.prices.findIndex(el => el.id === Number(id))
    console.log(getters.prices[idx])
    return new Promise((resolve, reject) => {
      pricesService
        .updatePrice(getters.prices[idx], idx)
        .then(() => resolve())
        .catch(error => {
          console.log(error)
          // if (error.response) { commit("SET_CREATE_CLIENT_FORM_ERROR", error.response.data.message); }
          reject()
        })
    })
  },
  async deletePrice({ getters, commit }, id) {
    let prices = JSON.parse(JSON.stringify(getters.prices))
    const idx = prices.findIndex(item => item.id === id)
    if (idx >= 0) {
      prices.splice(idx, 1)
      commit("SET_PRICES", prices)
      await pricesService.deletePrice(id)
    }
  },
}
