export default [
  {
    key: 1,
    dealerName: "АВТОГРАД ПРЕСТИЖ",
    orderedLinesAmount: "560849",
    orderedLinesPresence: "270451",
    orderedLinesSroch: "235410",
    orderedLinesSklad: "22560",
    orderedLinesOrder: "10900",
    orderedLinesOtherDealers: "14328",
    orderedLinesOther: "7200",
  },
  {
    key: 2,
    dealerName: "БОВИД АВТО",
    orderedLinesAmount: "318140",
    orderedLinesPresence: "104695",
    orderedLinesSroch: "35609",
    orderedLinesSklad: "86972",
    orderedLinesOrder: "48755",
    orderedLinesOtherDealers: "25901",
    orderedLinesOther: "16208",
  },
  {
    key: 3,
    dealerName: "АВТОГРАД ПЛЮС",
    orderedLinesAmount: "952761",
    orderedLinesPresence: "438930",
    orderedLinesSroch: "272431",
    orderedLinesSklad: "135780",
    orderedLinesOrder: "35800",
    orderedLinesOtherDealers: "12850",
    orderedLinesOther: "56970",
  },
  {
    key: 4,
    dealerName: "ПЛАНЕТА АВТО",
    orderedLinesAmount: "232345",
    orderedLinesPresence: "112880",
    orderedLinesSroch: "65815",
    orderedLinesSklad: "40810",
    orderedLinesOrder: "12840",
    orderedLinesOtherDealers: "0",
    orderedLinesOther: "0",
  },
  {
    key: 5,
    dealerName: "АРКОНТ",
    orderedLinesAmount: "239777",
    orderedLinesPresence: "103560",
    orderedLinesSroch: "89512",
    orderedLinesSklad: "3300",
    orderedLinesOrder: "6105",
    orderedLinesOtherDealers: "2100",
    orderedLinesOther: "35200",
  },
]
