/**
 * NSTO_OPERATOR_LOYALTY
 */

export default (() => {
  return {
    NSTO_OPERATOR_LOYALTY: {
      defaultSelectedId: ["8"],
      items: [
        {
          id: "8",
          icon: "exclamation-circle",
          name: "Дилеры",
          path: "/dealers",
        },
        // {
        //   id: "Promotions",
        //   name: "Акции",
        //   icon: "percentage",
        //   path: "/promotions",
        // },
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        {
          id: "Prices",
          name: "Прайс-Листы",
          icon: "container",
          path: "/prices",
        },
      ],
    },
  }
})()
