import axios from "axios"
import { message } from "ant-design-vue"
import { API_PREFIX } from "@/services/api/v1/const"

export default class BasketService {
  async getBasket() {
    return new Promise((resolve, reject) => {
      axios
        .get(`${API_PREFIX}/basket?XDEBUG_SESSION=PHPSTORM`)
        .then(response => resolve(response))
        .catch(error => {
          if (error.response.status === 404) {
            resolve({
              data: {
                products: [],
              },
            })
            return
          }

          message.error(error.response.data.message)
          reject(error.response.data.message)
        })
    })
  }
  async addProduct(payload, basket) {
    try {
      return await axios.put(
        `${API_PREFIX}/basket/${basket.uuid}/product`,
        payload
      )
    } catch (error) {
      message.error(error.response.data.message)
    }
  }

  async addProducts(payload, basket) {
    try {
      return await axios.put(
        `${API_PREFIX}/basket/${basket.uuid}/products`,
        payload
      )
    } catch (error) {
      message.error(error.response.data.message)
    }
  }

  async updateBasket(payload) {
    try {
      return await axios.patch(`${API_PREFIX}/basket/${payload.uuid}`, payload)
    } catch (error) {
      message.error(error.response.data.message)
      throw error
    }
  }

  async clearBasket(payload) {
    try {
      return await axios.post(`${API_PREFIX}/basket/${payload.uuid}/clear`)
    } catch (error) {
      message.error(error.response.data.message)
    }
  }

  async deleteFromBasket(payload) {
    try {
      let basket_number = parseInt(payload.number)
      await axios.delete(
        `${API_PREFIX}/basket/${payload.basket.uuid}/product/` + basket_number
      )
    } catch (error) {
      message.error(error.message)
    }
  }

  async verifyBasket(payload) {
    return await axios.post(
      `${API_PREFIX}/basket/${payload.basket.uuid}/verify`,
      payload
    )
  }
}
