import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class ClientService {
  async search(paymentGroup, name, inn, dealer) {
    const options = {}

    if (paymentGroup) {
      options.paymentGroup = paymentGroup
    }
    if (name) {
      options["legalInfo.name"] = name
    }
    if (inn) {
      options["legalInfo.inn"] = inn
    }
    if (dealer) {
      options["dealer"] = dealer
    }
    return axios.get(
      `${API_PREFIX}/client?${new URLSearchParams(options).toString()}`
    )
  }
  async searchglobal(paymentGroup, name, inn, dealer) {
    const options = {}

    if (paymentGroup) {
      options.paymentGroup = paymentGroup
    }
    if (name) {
      options["legalInfo.name"] = name
    }
    if (inn) {
      options["legalInfo.inn"] = inn
    }
    if (dealer) {
      options["dealer"] = dealer
    }

    return axios.get(
      `${API_PREFIX}/clientsearch?${new URLSearchParams(options).toString()}`
    )
  }
  async getSingleClient(id) {
    return axios.get(`${API_PREFIX}/client/${id}`)
  }
  async bindClient(userId, clientId) {
    return axios.put(`${API_PREFIX}/client`, {
      userId,
      clientId,
    })
  }
  async delSingleClient(id) {
    return axios.delete(`${API_PREFIX}/client/${id}`)
  }
  async updateClient(createForm) {
    console.log(createForm)
    return axios.patch(`${API_PREFIX}/client/${createForm.id}`, {
      name: createForm.name,
      paymentGroupId: createForm.paymentGroup.id,
      headName: createForm.headName,
      headPosition: createForm.headPosition,
      legalInfo: {
        name: createForm.legalInfoName,
        paymentAccount: createForm.legalInfoPaymentAccount,
        bankId: createForm.legalInfoBankId,
        bankName: createForm.legalInfoBankName,
        correspondedAccount: createForm.legalInfoCorrespondedAccount,
        underlyingDocument: createForm.legalInfoUnderlyingDocument,
        inn: createForm.legalInfoInn,
        kpp: createForm.legalInfoKpp,
        ogrn: createForm.legalInfoOgrn,
        okpo: createForm.legalInfoOkpo,
        address: createForm.legalInfoAddress,
        physicalAddress: createForm.legalInfoPhysicalAddress,
      },
      dealer_id: createForm.dealer_id,
      delivery_addresses: createForm.delivery_addresses,
      delivery_type: createForm.delivery_type,
      cdek_doc: createForm.cdek_doc,
      mass_restriction: createForm.mass_restriction,
      contacts: createForm.contacts,
      importer_delivery_period: createForm.importer_delivery_period,
      dealer_delivery_period: createForm.dealer_delivery_period,
    })
  }
  async createClientUser(userInfo) {
    return axios.post(`${API_PREFIX}/client/user`, userInfo.user)
  }
  async geClientUsers() {
    return axios.get(`${API_PREFIX}/client/users?page=1&per_page=50`)
  }
  async delSingleClientUsers(id) {
    return axios.delete(`${API_PREFIX}/client/users/${id}`)
  }
  async updateClientUser(id, user) {
    return axios.patch(`${API_PREFIX}/client/users/${id}`, user)
  }
}
