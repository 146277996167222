export default [
  {
    key: 1,
    dealer: "АВТОГРАД ПРЕСТИЖ",
    january: 8,
    february: 7,
    march: 7,
    april: 3,
    may: 2,
    june: 2,
    july: 4,
    august: 9,
    september: 7,
    october: 10,
    november: 1,
  },
  {
    key: 2,
    dealer: "БОВИД АВТО",
    january: 9,
    february: 5,
    march: 5,
    april: 9,
    may: 5,
    june: 5,
    july: 10,
    august: 12,
    september: 18,
    october: 14,
    november: 12,
  },
  {
    key: 3,
    dealer: "АВТОГРАД ПЛЮС",
    january: 19,
    february: 17,
    march: 39,
    april: 24,
    may: 6,
    june: 9,
    july: 22,
    august: 17,
    september: 19,
    october: 25,
    november: 26,
  },
  {
    key: 4,
    dealer: "ПЛАНЕТА АВТО",
    january: 3,
    february: 6,
    march: 7,
    april: 4,
    may: 5,
    june: 3,
    july: 6,
    august: 6,
    september: 7,
    october: 5,
    november: 9,
  },
  {
    key: 5,
    dealer: "АРКОНТ",
    january: 1,
    february: 3,
    march: 3,
    april: 4,
    may: 3,
    june: 4,
    july: 3,
    august: 16,
    september: 38,
    october: 68,
    november: 5,
  },
]
