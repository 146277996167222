import axios from "axios"
import { message } from "ant-design-vue"

import { API_PREFIX } from "@/services/api/v1/const"

export default class OrderService {
  getOrders(limit, offset) {
    const params = {
      limit,
      offset,
    }

    return axios.get(`${API_PREFIX}/order`, {
      params,
    })
  }
  getOrder(id) {
    return axios.get(`${API_PREFIX}/order/${id}`)
  }
  getStatuses() {
    return axios.get(`${API_PREFIX}/order/statuslist`)
  }
  refundProduct(orderId, productId, count) {
    try {
      return axios.post(`${API_PREFIX}/order/${orderId}/refund`, {
        productId,
        count,
      })
    } catch (error) {
      message.error(error.message)
    }
  }
  createOrder(order) {
    try {
      return axios.post(`${API_PREFIX}/order`, order)
    } catch (error) {
      message.error(error.message)
    }
  }
  updateOrder(id, external_number) {
    try {
      return axios.put(`${API_PREFIX}/order/${id}`, { external_number })
    } catch (error) {
      message.error(error.message)
    }
  }
  getCorrections(id) {
    try {
      return axios.get(`${API_PREFIX}/order/${id}/corrections`)
    } catch (error) {
      message.error(error.message)
    }
  }
  getRefundStatuses() {
    return axios.get(`${API_PREFIX}/order/refundstatusstat`)
  }
  getOrderStatuses() {
    return axios.get(`${API_PREFIX}/order/orderstatusstat`)
  }
  getCorrectionProducts() {
    try {
      return axios.get(`${API_PREFIX}/correctionproducts?include=order`)
    } catch (error) {
      message.error(error.message)
    }
  }
  deleteCorrectionProducts(id) {
    try {
      return axios.delete(`${API_PREFIX}/correctionproducts/${id}`)
    } catch (error) {
      message.error(error.message)
    }
  }
  createCorrection(id, data) {
    try {
      return axios.post(`${API_PREFIX}/order/${id}/correction`, data)
    } catch (error) {
      message.error(error.message)
    }
  }
  changeStatusCorrection(id, data) {
    try {
      return axios.post(`${API_PREFIX}/order/${id}/to/correction`, data)
    } catch (error) {
      message.error(error.message)
    }
  }
  submitCorrection(id, data) {
    try {
      return axios.post(
        `${API_PREFIX}/order/${id}/to/correction-completed`,
        data
      )
    } catch (error) {
      message.error(error.message)
    }
  }
  confirmOrder(order) {
    try {
      return axios.post(`${API_PREFIX}/order/${order.id}/confirm`, order)
    } catch (error) {
      message.error(error.message)
    }
  }
  declineOrder(order, comment) {
    try {
      return axios.post(`${API_PREFIX}/order/${order.id}/decline`, { comment })
    } catch (error) {
      message.error(error.message)
    }
  }
  setstatus(order, status, comment = "") {
    try {
      return axios.post(`${API_PREFIX}/order/${order.uuid}/setstatus`, {
        status,
        comment,
      })
    } catch (error) {
      message.error(error.message)
    }
  }
  sendNotification(orderId) {
    try {
      return axios.post(
        `${API_PREFIX}/order/${orderId}/ordersenddeliverynotify`
      )
    } catch (error) {
      message.error(error.message)
    }
  }
}
