import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class CreateDealerService {
  async createDealer(createForm) {
    return axios.post(`${API_PREFIX}/dealer`, {
      name: createForm.name,
      headName: createForm.headName,
      headPosition: createForm.headPosition,
      legalInfo: {
        name: createForm.legalInfoName,
        paymentAccount: createForm.legalInfoPaymentAccount,
        bankId: createForm.legalInfoBankId,
        bankName: createForm.legalInfoBankName,
        correspondedAccount: createForm.legalInfoCorrespondedAccount,
        underlyingDocument: createForm.legalInfoUnderlyingDocument,
        inn: createForm.legalInfoInn,
        kpp: createForm.legalInfoKpp,
        ogrn: createForm.legalInfoOgrn,
        okpo: createForm.legalInfoOkpo,
        address: createForm.legalInfoAddress,
        physicalAddress: createForm.legalInfoPhysicalAddress,
        phone: createForm.legalInfoPhone,
        site: createForm.legalInfoSite,
      },
      contacts: createForm.contacts,
      user: createForm.user,
      delivery_period: createForm.delivery_period,
      refund_days: createForm.refund_days,
      external_id: createForm.external_id,
    })
  }
  async updateDealer(createForm) {
    return axios.put(`${API_PREFIX}/dealer/${createForm.id}`, {
      name: createForm.name,
      headName: createForm.headName,
      headPosition: createForm.headPosition,
      legalInfo: {
        name: createForm.legalInfoName,
        paymentAccount: createForm.legalInfoPaymentAccount,
        bankId: createForm.legalInfoBankId,
        bankName: createForm.legalInfoBankName,
        correspondedAccount: createForm.legalInfoCorrespondedAccount,
        underlyingDocument: createForm.legalInfoUnderlyingDocument,
        inn: createForm.legalInfoInn,
        kpp: createForm.legalInfoKpp,
        ogrn: createForm.legalInfoOgrn,
        okpo: createForm.legalInfoOkpo,
        address: createForm.legalInfoAddress,
        physicalAddress: createForm.legalInfoPhysicalAddress,
        phone: createForm.legalInfoPhone,
        site: createForm.legalInfoSite,
      },
      contacts: createForm.contacts,
      user: createForm.user,
      delivery_period: createForm.delivery_period,
      refund_days: createForm.refund_days,
      external_id: createForm.external_id,
    })
  }
}
