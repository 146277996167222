/* eslint-disable max-statements */
export default function convertVtableOptions(options) {
  options = { ...options }

  if (options.sortBy) {
    const sort = [...options.sortBy]
    delete options.sortBy
    sort.forEach((value, index) => {
      if (!options.sortDesc[index]) {
        sort[index] = `-${value}`
      }
    })
    options.sort = sort.join(',')
  }
  if (options.pageSize) {
    options.per_page = options.pageSize
    delete options.pageSize
  }
  if (options.current) {
    options.page = options.current
    delete options.current
  }
  const filters = options.filters
  for (const filter in filters) {
    if (filters[filter] !== null && filters[filter] !== undefined) {
      options[`filter[${filter}]`] = filters[filter]
    }
  }
  delete options.filters
  return options
}
