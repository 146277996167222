/**
 * DEALER_ANALYST
 */

export default (() => {
  return {
    DEALER_ANALYST: {
      defaultSelectedId: ["Reports"],
      items: [
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        {
          id: "Clients",
          name: "Клиенты",
          icon: "team",
          roles: ["Dealer"],
          children: {
            items: [
              {
                id: "Clients",
                name: "Список клиентов",
                path: "/clients",
              },
              {
                id: "CheckClientExist",
                name: "Регистрация нового клиента",
                path: "/createclient?name=&inn=",
              },
            ],
          },
        },
        {
          id: "Prices",
          name: "Прайс-листы",
          icon: "container",
          path: "/prices",
        },
      ],
    },
  }
})()
