import Vue from "vue"
import Vuex from "vuex"

import modules from "@/store/modules"

Vue.use(Vuex)

const store = new Vuex.Store({
  // plugins: [createLogger()],
  strict: process.env.NODE_ENV !== "production",
})

Object.keys(modules).map(name => store.registerModule(name, modules[name]))

// Automatically run the `init` action for every module,
// if one exists.
for (const moduleName of Object.keys(modules)) {
  if (modules[moduleName].actions && modules[moduleName].actions.init) {
    store.dispatch(`${moduleName}/init`)
  }
}

export default store
