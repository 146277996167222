/**
 * NSTO_ANALYST
 */

export default (() => {
  return {
    NSTO_ANALYST: {
      defaultSelectedId: ["Reports"],
      items: [
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
      ],
    },
  }
})()
