import createCrudModule from "@/utils/vuex-crud/index.js"
const mod = createCrudModule({
  parseList: resp => resp.data,
  parseSingle: resp => resp.data,
  resource: "dealer",
  state: {},
})
export const state = mod.state
export const actions = mod.actions
export const mutations = mod.mutations
export const getters = mod.getters
