import { DummyApiService } from "@/services"
const service = new DummyApiService()

export const state = {
  list: [],
  columns: [
    {
      title: "Бренд",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "Название файла",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Дата загрузки",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
    },
    {
      title: "",
      scopedSlots: { customRender: "download" },
    },
    {
      title: "Способ загрузки",
      dataIndex: "uploadMethod",
      key: "uploadMethod",
    },
  ],
  emptyColumns: [
    {
      title: "",
      dataIndex: "brand",
      key: "brand",
    },
    {
      title: "",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "",
      dataIndex: "uploadedAt",
      key: "uploadedAt",
    },
    {
      title: "",
      scopedSlots: { customRender: "download" },
    },
    {
      title: "",
      dataIndex: "uploadMethod",
      key: "uploadMethod",
    },
  ],
  uploadForm: {
    currentBrand: 1,
    file: null,
  },
  preview: {
    list: [],
    columns: [
      {
        title: "Артикул",
        dataIndex: "article",
        key: "article",
      },
      {
        title: "Актуальный артикул",
        dataIndex: "actualArticle",
        key: "actualArticle",
      },
      {
        title: "Наличие",
        dataIndex: "availability",
        key: "availability",
      },
      {
        title: "Минимальное количество мест во",
        dataIndex: "minSeatsAmount",
        key: "minSeatsAmount",
      },
      {
        title: "Наименование",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Розничная цена",
        dataIndex: "retailPrice",
        key: "retailPrice",
      },
      {
        title: "Стоковый заказ",
        dataIndex: "stockOrder",
        key: "stockOrder",
      },
      {
        title: "Срочный заказ",
        dataIndex: "expressOrder",
        key: "expressOrder",
      },
    ],
  },
}

export const mutations = {
  SET_CS_PRICES: (state, payload) => (state.list = payload),
  CHOOSE_BRAND: (state, payload) => (state.uploadForm.currentBrand = payload),
  SET_CS_PRICES_PREVIEW: (state, payload) => (state.preview.list = payload),
}

export const actions = {
  fetchCsPrices: async ({ commit }) => {
    const list = await service.getCsPrices()
    commit("SET_CS_PRICES", list)
  },
  /* fetchCsPricesPreview: async ({ commit, getters }) => {
      const link = await service.getCsPricesPreview(getters.currentBrand);
      //commit("SET_CS_PRICES_PREVIEW", list);
      commit("SET_CS_PRICES_PREVIEW", link);
    }*/
}

export const getters = {
  list: state => state.list,
  columns: state => state.columns,
  emptyColumns: state => state.emptyColumns,
  previewColumns: state => state.preview.columns,
  currentBrand: state => state.uploadForm.currentBrand,
  previews: state => state.preview.list,
}
