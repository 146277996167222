export default [
  {
    key: 1,
    dealer: "АВТОГРАД ПРЕСТИЖ",
    january: 89,
    february: 91,
    march: 117,
    april: 45,
    may: 10,
    june: 13,
    july: 115,
    august: 92,
    september: 84,
    october: 85,
    november: 71,
  },
  {
    key: 2,
    dealer: "БОВИД АВТО",
    january: 147,
    february: 126,
    march: 199,
    april: 99,
    may: 51,
    june: 83,
    july: 120,
    august: 167,
    september: 166,
    october: 135,
    november: 137,
  },
  {
    key: 3,
    dealer: "АВТОГРАД ПЛЮС",
    january: 152,
    february: 197,
    march: 256,
    april: 213,
    may: 79,
    june: 68,
    july: 157,
    august: 239,
    september: 350,
    october: 456,
    november: 213,
  },
  {
    key: 4,
    dealer: "ПЛАНЕТА АВТО",
    january: 35,
    february: 31,
    march: 53,
    april: 36,
    may: 31,
    june: 33,
    july: 42,
    august: 59,
    september: 45,
    october: 21,
    november: 35,
  },
  {
    key: 5,
    dealer: "АРКОНТ",
    january: 15,
    february: 19,
    march: 32,
    april: 17,
    may: 12,
    june: 37,
    july: 48,
    august: 40,
    september: 42,
    october: 17,
    november: 87,
  },
]
