/**
 * DEALER_OPERATOR
 */

export default (() => {
  return {
    DEALER_OPERATOR: {
      defaultSelectedId: ["4"],
      items: [
        {
          id: "Prices",
          name: "Прайс-листы",
          icon: "container",
          path: "/prices",
          // children: {
          //   items: [
          // {
          //   id: "LocalPrices",
          //   name: "Прайс-листы",
          //   path: "/prices",
          // },
          /*{
                id: "ClientPrices",
                name: "Клиентские прайс-листы",
                path: "/clientprices",
              },*/
          // {
          //   id: "PricesCS",
          //   name: "Прайс-листы ЦС",
          //   path: "/pricescs",
          // },
          /*{
                id: "Markups",
                name: "Наценки",
                path: "/markups",
              },*/
          // {
          //   id: "PricesDelivery",
          //   name: "Рассылка прайс-листов",
          //   path: "/pricesdelivery",
          // },
          // {
          //   id: "DirectoriesCS",
          //   name: "Справочники ЦС",
          //   path: "/directoriescs",
          // },
          /*{
                id: "Directories",
                name: "Локальный справочник",
                path: "/directories",
              },*/
          // {
          //   id: "AllowedBrandsList",
          //   name: "Список разрешенных брендов",
          //   path: "/allowedbrands",
          // },
          //   ],
          // },
        },
        // {
        //   id: "Promotions",
        //   name: "Акции",
        //   icon: "percentage",
        //   path: "/promotions",
        // },
        {
          id: "Clients",
          name: "Клиенты",
          icon: "team",
          roles: ["Dealer"],
          children: {
            items: [
              {
                id: "Clients",
                name: "Список клиентов",
                path: "/clients",
              },
              {
                id: "CheckClientExist",
                name: "Регистрация нового клиента",
                path: "/createclient?name=&inn=",
              },
            ],
          },
        },
      ],
    },
  }
})()
