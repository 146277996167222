import createCrudModule from "@/utils/vuex-crud/index.js"
const mod = createCrudModule({
  parseList(response) {
    const { data } = response

    return Object.assign(
      {},
      {
        data: data.map((el, i) => ({ id: i, value: el })),
      }
    )
  },
  parseSingle(response) {
    const { data } = response

    return Object.assign(
      {},
      {
        data: data.map((el, i) => ({ id: i, value: el })),
      }
    )
  },
  resource: "product/pricegrouplist",
  state: {},
})
export const state = mod.state
export const actions = mod.actions
export const mutations = mod.mutations
export const getters = mod.getters
