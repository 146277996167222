export default [
  {
    key: 1,
    dealer: "АВТОГРАД ПРЕСТИЖ",
    connectedUlAmount: "14",
    activeUlAmount: "8",
    orderedLinesAmount: "75",
    declinedLinesAmount: "8",
    approvedLinesAmount: "62",
    statuslessLinesAmount: "5",
    declineKef: "0,11",
    approveKef: "0,83",
    departedLinesAmount: "92",
    nalOrderPercent: "0,47",
    srochCSOrderPercent: "0,32",
    skladCSOrderPercent: "0,12",
    orderedCSOrderPercent: "0,03",
    otherOrderPercent: "0,07",
    address: "Республики 280, Тюмень, Ханты-Мансийский, 625014",
  },
  {
    key: 2,
    dealer: "БОВИД АВТО",
    connectedUlAmount: "25",
    activeUlAmount: "18",
    orderedLinesAmount: "127",
    declinedLinesAmount: "24",
    approvedLinesAmount: "103",
    statuslessLinesAmount: "0",
    declineKef: "0,19",
    approveKef: "0,81",
    departedLinesAmount: "89",
    nalOrderPercent: "0,50",
    srochCSOrderPercent: "0,09",
    skladCSOrderPercent: "0,27",
    orderedCSOrderPercent: "0,03",
    otherOrderPercent: "0,11",
    address: "Ленина проспект 26А стр.2, Челябинск, Челябинская, 454007",
  },
  {
    key: 3,
    dealer: "АВТОГРАД ПЛЮС",
    connectedUlAmount: "31",
    activeUlAmount: "21",
    orderedLinesAmount: "317",
    declinedLinesAmount: "26",
    approvedLinesAmount: "275",
    statuslessLinesAmount: "16",
    declineKef: "0,08",
    approveKef: "0,87",
    departedLinesAmount: "213",
    nalOrderPercent: "0,67",
    srochCSOrderPercent: "0,11",
    skladCSOrderPercent: "0,15",
    orderedCSOrderPercent: "0,03",
    otherOrderPercent: "0,03",
    address: "Ленинский проспект, 156, Воронеж, Воронежская обл., 394042",
  },
  {
    key: 4,
    dealer: "ПЛАНЕТА АВТО",
    connectedUlAmount: "6",
    activeUlAmount: "6",
    orderedLinesAmount: "39",
    declinedLinesAmount: "9",
    approvedLinesAmount: "29",
    statuslessLinesAmount: "1",
    declineKef: "0,23",
    approveKef: "0,74",
    departedLinesAmount: "48",
    nalOrderPercent: "0,38",
    srochCSOrderPercent: "0,31",
    skladCSOrderPercent: "0,28",
    orderedCSOrderPercent: "0,03",
    otherOrderPercent: "0,00",
    address: "Братьев Кашириных 143, Челябинск, Челябинская обл, 454128",
  },
  {
    key: 5,
    dealer: "АРКОНТ",
    connectedUlAmount: "12",
    activeUlAmount: "9",
    orderedLinesAmount: "92",
    declinedLinesAmount: "10",
    approvedLinesAmount: "74",
    statuslessLinesAmount: "8",
    declineKef: "0,11",
    approveKef: "0,80",
    departedLinesAmount: "121",
    nalOrderPercent: "0,46",
    srochCSOrderPercent: "0,35",
    skladCSOrderPercent: "0,03",
    orderedCSOrderPercent: "0,03",
    otherOrderPercent: "0,13",
    address: "ул. Землячки 25, Волгоград, 400117",
  },
]
