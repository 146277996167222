import Vue from "vue"
import VueRouter from "vue-router"

const loadView = path => {
  return () =>
    import(
      /* webpackChunkName: "view-[request]" */
      `../views/${path}.vue`
    )
}

import store from "../store/store"
import EditPromotion from "@/components/promotions/promotion-edit"
Vue.use(VueRouter)

const routes = [
  {
    path: "/login",
    name: "Login",
    meta: { layout: "login", auth: false },
    component: loadView("Login"),
  },
  {
    path: "/resetpassword",
    name: "ResetPassword",
    meta: { layout: "reset", auth: false },
    component: loadView("ResetPassword"),
  },
  {
    path: "/clients",
    name: "Clients",
    meta: { layout: "main", auth: true, title: "Клиенты" },
    component: loadView("Clients"),
  },
  {
    path: "/dealers",
    name: "Dealer",
    meta: { layout: "main", auth: true, title: "Дилеры" },
    component: loadView("Dealers"),
  },
  {
    path: "/dealer/documents",
    name: "DealerDocuments",
    meta: { layout: "main", auth: true, title: "Документы" },
    component: loadView("DealerDocuments"),
  },
  {
    path: "/dealer/documents/:id",
    name: "ClientInfo",
    meta: { layout: "main", auth: true },
    component: loadView("ClientInfo"),
  },
  {
    path: "/buyer/documents",
    name: "BuyerDocuments",
    meta: { layout: "main", auth: true, title: "Документы" },
    component: loadView("BuyerDocuments"),
  },
  {
    path: "/dealer/:id",
    name: "DealerInfo",
    meta: { layout: "main", auth: true },
    component: loadView("DealerInfo"),
  },
  {
    path: "/orders",
    name: "Orders",
    meta: { layout: "main", auth: true, title: "Заказы" },
    component: loadView("Orders"),
  },
  {
    path: "/orders/corrections",
    name: "Corrections",
    meta: { layout: "main", auth: true, title: "Корректировки" },
    component: loadView("Corrections"),
  },
  {
    path: "/order/:id",
    name: "Order",
    meta: { layout: "main", auth: true },
    component: loadView("Order"),
  },
  {
    path: "/refunds",
    name: "Refunds",
    meta: { layout: "main", auth: true, title: "Возвраты" },
    component: loadView("Refunds"),
  },
  {
    path: "/refundscreate",
    name: "RefundsCreate",
    meta: { layout: "main", auth: true, title: "Создать возврат" },
    component: loadView("RefundsCreate"),
  },
  {
    path: "/refundscreate/:id",
    name: "RefundCreateForm",
    meta: { layout: "main", auth: true, title: "Создать возврат" },
    component: loadView("RefundCreateForm"),
  },
  {
    path: "/refunds/:id",
    name: "RefundDetails",
    meta: { layout: "main", auth: true },
    component: loadView("RefundPage"),
  },
  // {
  //   path: "/localprices",
  //   name: "LocalPrices",
  //   meta: { layout: "main", auth: true, title: "Локальные прайс-листы" },
  //   component: loadView("LocalPrices"),
  // },
  // {
  //   path: "/clientprices",
  //   name: "ClientPrices",
  //   meta: { layout: "main", auth: true, title: "Клиентские прайс-листы" },
  //   component: loadView("ClientPrices"),
  // },
  {
    path: "/localprices/:id",
    name: "LocalPricesSettings",
    meta: {
      layout: "main",
      auth: true,
      title: "Настройка локального прайс-листа",
    },
    component: loadView("LocalPricesSettings"),
  },
  {
    path: "/promotions",
    name: "Promotions",
    meta: { layout: "main", auth: true, title: "Акции" },
    component: loadView("Promotions"),
  },
  {
    path: "/promotions/edit/:id",
    name: "EditPromotion",
    meta: {
      layout: "main",
      auth: true,
      title: "Редактирование акции",
      //allowedRoles: ["IMPORTER_ADMIN", "DEALER_ADMIN"],
      //rolesForbiddenRedirect: "/403",
    },
    component: EditPromotion,
    props: true,
  },
  {
    path: "/promotions/create",
    name: "CreatePromotion",
    meta: {
      layout: "main",
      auth: true,
      title: "Создание акции",
      //allowedRoles: ["IMPORTER_ADMIN", "DEALER_ADMIN"],
      //rolesForbiddenRedirect: "/403",
    },
    component: EditPromotion,
    props: { create: true },
  },
  {
    path: "/reports",
    name: "Reports",
    meta: { layout: "main", auth: true, title: "Отчеты" },
    component: loadView("Reports"),
  },
  // {
  //   path: "/dealer/:id/buyers/reports",
  //   name: "DealerBuyersReports",
  //   meta: { layout: "main", auth: true },
  //   component: loadView("DealerBuyersReports"),
  // },
  // {
  //   path: "/dealer/:id/buyers/list",
  //   name: "DealerBuyersList",
  //   meta: { layout: "main", auth: true },
  //   component: loadView("DealerBuyers"),
  // },
  {
    path: "/dealerUser/:id",
    name: "EditDealerUser",
    meta: { layout: "main", auth: true },
    component: loadView("EditDealerUser"),
  },
  {
    path: "/user/:id",
    name: "EditUser",
    meta: { layout: "main", auth: true },
    component: loadView("EditUser"),
  },
  {
    path: "/clientUser/:id",
    name: "EditClientUser",
    meta: { layout: "main", auth: true },
    component: loadView("EditClientUser"),
  },
  {
    path: "/paymentgroups",
    name: "PaymentGroups",
    meta: { layout: "main", auth: true, title: "Группы опта" },
    component: loadView("PaymentGroups"),
  },
  // {
  //   path: "/reports/period",
  //   name: "ReportsPeriod",
  //   meta: { layout: "main", auth: true },
  //   component: loadView("ReportsPeriod"),
  // },
  // {
  //   path: "/reports/dynamic",
  //   name: "ReportsDynamic",
  //   meta: { layout: "main", auth: true },
  //   component: loadView("ReportsDynamic"),
  // },
  {
    path: "/reports/period-group",
    name: "ReportsPeriodGroup",
    meta: { layout: "main", auth: true },
    component: loadView("ReportsPeriodGroup"),
  },
  {
    path: "/reports/dynamic-group",
    name: "ReportsDynamicGroup",
    meta: { layout: "main", auth: true },
    component: loadView("ReportsDynamicGroup"),
  },
  {
    path: "/",
    name: "SearchDetails",
    meta: {
      layout: "main",
      auth: true,
      title: "Поиск запчастей",
      allowedRoles: [
        "ADMIN",
        "IMPORTER_ORDER_OPERATOR",
        "DEALER_ADMIN",
        "DEALER_ORDER_OPERATOR",
        "NSTO_ADMIN",
        "NSTO_ORDER_OPERATOR",
      ],
      rolesForbiddenRedirect: "/403",
    },
    component: loadView("SearchDetails"),
  },
  {
    path: "/clients/:id",
    name: "ClientInfo",
    meta: { layout: "main", auth: true, title: "Информация о клиенте" },
    component: loadView("ClientInfo"),
  },
  {
    path: "/basket",
    name: "Basket",
    meta: { layout: "main", auth: true, title: "Корзина" },
    component: loadView("Basket"),
  },
  // {
  //   path: "/markups",
  //   name: "Markups",
  //   meta: { layout: "main", auth: true, title: "Наценки" },
  //   component: loadView("Markups"),
  // },
  {
    path: "/createclient",
    name: "CreateNewClient",
    meta: { layout: "main", auth: true, title: "Создание клиента" },
    component: loadView("CreateNewClient"),
  },
  {
    path: "/createuser",
    name: "CreateNewUser",
    meta: { layout: "main", auth: true, title: "Создание пользователя" },
    component: loadView("CreateNewUser"),
  },
  {
    path: "/allusers",
    name: "AllUsers",
    meta: { layout: "main", auth: true, title: "Все пользователи" },
    component: loadView("AllUserList"),
  },
  {
    path: "/client/createuser",
    name: "CreateNewUser",
    meta: { layout: "main", auth: true, title: "Создание пользователя" },
    component: loadView("CreateNewClientUser"),
  },
  {
    path: "/checkclient",
    name: "CheckClientExist",
    meta: { layout: "main", auth: true, title: "Проверка клиента" },
    component: loadView("CheckClientExist"),
  },
  {
    path: "/createdealer",
    name: "CreateNewDealer",
    meta: { layout: "main", auth: true, title: "Создание дилера" },
    component: loadView("CreateNewDealer"),
  },
  {
    path: "/pricescs",
    name: "PricesCS",
    meta: { layout: "main", auth: true },
    component: loadView("PricesCS"),
  },
  {
    path: "/pricesdelivery",
    name: "PricesDelivery",
    meta: { layout: "main", auth: true },
    component: loadView("PricesDelivery"),
  },
  {
    path: "/directoriescs",
    name: "DirectoriesCS",
    meta: { layout: "main", auth: true },
    component: loadView("DirectoriesCS"),
  },
  {
    path: "/directories",
    name: "Directories",
    meta: { layout: "main", auth: true, title: "Локальный справочник" },
    component: loadView("Directories"),
  },
  {
    path: "/allowedbrands",
    name: "AllowedBrandsList",
    meta: { layout: "main", auth: true },
    component: loadView("AllowedBrandsList"),
  },
  {
    path: "/orderregistration",
    name: "OrderRegistration",
    meta: { layout: "main", auth: true, title: "Оформление заказа" },
    component: loadView("OrderRegistration"),
  },
  // {
  //   path: "/delivery",
  //   name: "Delivery",
  //   meta: { layout: "main", auth: true, title: "Доставка" },
  //   component: loadView("Delivery"),
  // },
  // {
  //   path: "/export",
  //   name: "Export",
  //   meta: { layout: "main", auth: true, title: "Экспорт" },
  //   component: loadView("Export"),
  // },
  {
    path: "/prices",
    name: "Prices",
    meta: { layout: "main", auth: true },
    component: loadView("Prices"),
  },
  {
    path: "/buyersdealer",
    name: "BuyersDealer",
    meta: { layout: "main", auth: true },
    component: loadView("BuyersDealer"),
  },
  {
    path: "/403",
    name: "403",
    component: {
      template: "<p>Forbidden</p>",
    },
  },
  {
    path: "/404",
    name: "404",
    component: {
      template: "<p>Page Not Found</p>",
    },
  },
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    const isLogged = store.getters["auth/isLoggedIn"]
    if (!isLogged) {
      next("/login")
      return
    }

    // const userRoles = store.getters["auth/rolesNamesList"]
    // const allowedRoles = to.meta.allowedRoles
    // if (
    //   allowedRoles &&
    //   !userRoles.some(userRole => allowedRoles.includes(userRole))
    // ) {
    //   next(to.meta.rolesForbiddenRedirect)
    // }

    next()
  } else {
    next()
  }
})

export default router
