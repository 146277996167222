export default [
  {
    key: 1,
    name: "AVALON AVTO Воронеж",
    address: "394024, Россия, Воронеж, ул. 45 Стрелковой Дивизии 22",
    phone: "+7 (910) 348-78-37",
    site: "http://avalon-a.ru",
    legalEntity: "ИП Лопаев А.Н.",
    inn: "366102062500",
    connectedAt: "12.05.2020",
    ordersSum: 274503,
    departedSum: 356805,
  },
  {
    key: 2,
    name: "Autopower",
    address: "Россия, Воронежская область, Воронеж, ул. Волгоградская 41В",
    phone: "+7 (900) 960-98-65",
    site: "http://autopower36.ru",
    legalEntity: 'ООО "ДОМ-СЕРВИС"',
    inn: "3666244340",
    connectedAt: "01.03.2020",
    ordersSum: 149850,
    departedSum: 269510,
  },
  {
    key: 3,
    name: "Avalon Avto на Куколкина",
    address: "Россия, Воронежская область, Воронеж, ул. Куколкина 33",
    phone: "+7 (952) 957-98-88",
    site: "http://avalon-a.ru",
    legalEntity: "ИП Моторный В.В.",
    inn: "366104088279",
    connectedAt: "24.07.2020",
    ordersSum: 145700,
    departedSum: 98420,
  },
  {
    key: 4,
    name: "ВсеАвто",
    address:
      "394033, Россия, Воронежская область, Воронеж, пр. Ленинский 174-5",
    phone: "+7 (900) 925-45-48",
    site: "http://www.va36.ru",
    legalEntity: "ИП Кириллов Ю.А.",
    inn: "366308262447",
    connectedAt: "01.08.2020",
    ordersSum: 139401,
    departedSum: 39100,
  },
  {
    key: 5,
    name: "ООО ТОТА",
    address: "394019, Россия, Воронеж, ул. Машиностроителей 3Б-2",
    phone: "+7 (903) 856-87-77",
    site: "https://www.totapart.ru",
    legalEntity: 'ООО "ТОТА"',
    inn: "3662999260",
    connectedAt: "01.02.2020",
    ordersSum: 87050,
    departedSum: 45870,
  },
  {
    key: 6,
    name: "ИП Латыпов",
    address: "394026, Россия, Воронежская область, Воронеж, ул. Солнечная 31",
    phone: "+7 (910) 701-63-02",
    site: "",
    legalEntity: "ИП Латыпов С.М.",
    inn: "366304980904",
    connectedAt: "06.08.2020",
    ordersSum: 59704,
    departedSum: 33500,
  },
  {
    key: 7,
    name: "АНГЛИЯ СЕРВИС",
    address: "Россия, Воронежская область, Воронеж, ул. Волгоградская 37",
    phone: "+7 (912) 311-78-72",
    site: "",
    legalEntity: "ИП Песегов Э.В.",
    inn: "366312784521",
    connectedAt: "03.03.2020",
    ordersSum: 33271,
    departedSum: 25860,
  },
  {
    key: 8,
    name: "Сервис реек",
    address: "Россия, Воронежская область, Воронеж, ул. Бурденко, 12",
    phone: "+7 (952) 912-91-08",
    site: "",
    legalEntity: "ИП Зобнин А.К.",
    inn: "366143934237",
    connectedAt: "21.07.2020",
    ordersSum: 21502,
    departedSum: 88590,
  },
  {
    key: 9,
    name: "ДИЛЕР-ПАРТС",
    address: "129128, Москва, Бажова, 17 стр.14, эт.2, пом.1",
    phone: "+7 (495) 315-45-78",
    site: "https://www.dealer-parts.ru",
    legalEntity: 'ООО "Дилер-Партс"',
    inn: "7734392516",
    connectedAt: "25.04.2020",
    ordersSum: 16740,
    departedSum: 35805,
  },
  {
    key: 10,
    name: "БРИТПАРТ",
    address: "105082, Россия, Москва, ул. Новая Переведеновская 1/7-1",
    phone: "+7 (926) 223-70-04",
    site: "http://brit-part.ru",
    legalEntity: "ООО «Бьюти Дент клиник»",
    inn: "7708711036",
    connectedAt: "01.09.2020",
    ordersSum: 12300,
    departedSum: 12300,
  },
]
