import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class RefundsService {
  async create(formData) {
    return axios.post(`${API_PREFIX}/refunds`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
  }
  async setstatus(formData) {
    return axios.post(
      `${API_PREFIX}/refunds/${formData.get("id")}/setstatus`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
  }
  async getActions(id) {
    const resp = await axios.get(`${API_PREFIX}/refunds/${id}/actions`)
    return resp.data
  }
}
