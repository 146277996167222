import SupplierService from "@/services/api/v1/SupplierService"

const service = new SupplierService()

export const state = {
  suppliers: [],
  columns: [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Наименование импортера",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      width: 250,
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      width: 120,
    },
    {
      title: "ФИО",
      dataIndex: "head_name",
      key: "head_name",
      width: 180,
    },
    {
      title: "Архив",
      dataIndex: "operation",
      scopedSlots: { customRender: "operation" },
      className: "column-action",
      width: 80,
    },
  ],
  createForm: {},
  createFormError: null,
  paginationOptions: {
    pageSize: 10,
    totalCount: 0,
    currentPage: 1,
  },
  filters: {
    name: null,
  },
}

export const getters = {
  suppliers: state => state.suppliers,
  columns: state => state.columns,
  paginationOptions: state => state.paginationOptions,
  filters: state => state.filters,
  createForm: state => state.createForm,
}

export const mutations = {
  SET_SUPPLIERS(state, payload) {
    state.suppliers = payload
  },
  SET_SUPPLIERS_TOTAL_COUNT(state, payload) {
    state.paginationOptions.totalCount = payload
  },
  CLEAR_CREATE_SUPPLIER_FORM_ERROR(state) {
    state.createFormError = null
  },
  SET_CREATE_FORM_FIELD(state, payload) {
    state.createForm[payload.name] = payload.value
  },
  SET_CREATE_FORM(state, payload) {
    state.createForm = payload
  },
  SET_CURRENT_PAGE(state, payload) {
    state.paginationOptions.currentPage = payload
  },
  CLEAR_FILTERS(state) {
    state.filters.name = null
  },
  SET_FILTER_NAME(state, payload) {
    state.filters.name = payload
  },
}

export const actions = {
  async fetchSuppliers({ commit, getters }) {
    const paginationOptions = getters.paginationOptions
    const filters = getters.filters

    const response = await service.search(
      filters.name,
      paginationOptions.currentPage,
      paginationOptions.pageSize
    )

    commit("SET_SUPPLIERS", response.data.data)
    commit("SET_SUPPLIERS_TOTAL_COUNT", response.data.meta.total)
  },

  async createSupplier({ commit, getters }, userResp) {
    const supplierFormData = getters.createForm

    let createForm = Object.assign(userResp, supplierFormData)
    let postData = {}
    postData = {
      name: createForm.name,
      phone: createForm.phone,
      head_name: createForm.head_name,
      head_position: createForm.head_position,
      legal_name: createForm.legal_name,
      legal_address: createForm.legal_address,
      physical_address: createForm.physical_address,
      inn: createForm.inn,
    }

    // console.log(postData)
    return new Promise((resolve, reject) => {
      commit("CLEAR_CREATE_SUPPLIER_FORM_ERROR")
      service
        .createSupplier(postData)
        .then(resp => resolve(resp))
        .catch(error => {
          reject(error.response)
        })
    })
  },

  async getSupplier({ commit }, id) {
    const response = await service.getSupplier(id)
    const data = response.data.data
    let formObj = {
      id: data.id,
      name: data.name,
      phone: data.phone,
      head_name: data.details.head_name,
      head_position: data.details.head_position,
      legal_name: data.details.legal_name,
      legal_address: data.details.legal_address,
      physical_address: data.details.physical_address,
      inn: data.details.inn,
    }
    commit("SET_CREATE_FORM", formObj)
  },

  async updateSupplier({ commit, getters }) {
    const formData = JSON.parse(JSON.stringify(getters.createForm))

    return new Promise((resolve, reject) => {
      commit("CLEAR_CREATE_SUPPLIER_FORM_ERROR")
      service
        .updateSupplier(formData)
        .then(() => resolve())
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  },

  async deleteSupplier(_, id) {
    await service.deleteSupplier(id)
  },
}
