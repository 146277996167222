<template>
  <div class="promotions-page content-container">
    <a-spin :spinning="loading">
      <div class="spin-content">
        <a-form-model
          :model="form"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
          :rules="rules"
          ref="ruleForm"
        >
          <a-form-model-item label="Название" prop="title">
            <a-input v-model="form.title" />
          </a-form-model-item>
          <a-form-model-item label="Ссылка на акцию" prop="link">
            <a-input v-model="form.link" />
          </a-form-model-item>
          <a-form-model-item label="Изображение" prop="image">
            <a-upload
              :multiple="false"
              accept=".png,.jpg,.jpeg"
              list-type="picture-card"
              class="avatar-uploader"
              :show-upload-list="{
                showPreviewIcon: false,
                showRemoveIcon: true,
              }"
              :before-upload="beforeUpload"
              :remove="onFileRemove"
              @change="handleChange"
              :file-list="uploadImages"
            >
              <a-icon :type="'plus'" />
              <div class="ant-upload-text">
                Выбрать изображение
              </div>
            </a-upload>
          </a-form-model-item>
          <a-form-model-item label="Даты действия акции">
            <a-date-picker
              v-model="form.date_from"
              show-time
              type="date"
              placeholder="Дата начала"
              style="width: 100%;"
            />
            <a-date-picker
              v-model="form.date_to"
              show-time
              type="date"
              placeholder="Дата завершения"
              style="width: 100%;"
            />
          </a-form-model-item>
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button v-if="!id" type="primary" @click="onSubmit">
              Создать
            </a-button>
            <a-space v-else>
              <a-button type="primary" @click="onSubmit">
                Сохранить
              </a-button>
              <a-button type="danger" @click="onDelete">
                Удалить
              </a-button>
            </a-space>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex"

export default {
  name: "PromotionEdit",
  props: ["id", "create"],
  data() {
    return {
      loading: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      imageUrl: null,
      uploadImages: [],
      form: {
        title: "",
        link: "",
        date_from: "",
        date_to: "",
        image: "",
      },
      rules: {
        title: [
          {
            required: true,
            message: "Введите название акции",
            trigger: "change",
          },
        ],
        image: [
          {
            required: true,
            message: "Выберите изображение для акции",
            trigger: "change",
          },
        ],
        link: [
          {
            required: true,
            message: "Введите ссылку на акцию",
            trigger: "change",
          },
          {
            type: "url",
            message: "Не верный формат ссылки",
            trigger: "change",
          },
        ],
      },
    }
  },
  async mounted() {
    if (this.id) {
      this.loading = true
      await this.fetchPromotion(this.id)
      const tmp = {}
      for (const key in this.form) {
        tmp[key] = this.currentPromotion[key]
      }
      this.form = { ...tmp }
      this.uploadImages = [
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: this.form.image,
        },
      ]
      this.loading = false
    }
  },
  computed: mapGetters({
    currentPromotion: "promotions/currentPromotion",
  }),
  methods: {
    async submitData() {
      const fd = new FormData()
      for (const key in this.form) {
        if (Object.hasOwnProperty.call(this.form, key)) {
          const element = this.form[key]
          if (!element) continue
          if (key == "image" && typeof element !== "string")
            fd.append("file", element)
          else fd.append(key, element)
        }
      }
      this.loading = true
      try {
        if (this.id) {
          await this.updatePromotion({ id: this.currentPromotion.id, data: fd })
          this.$message.success("Акция успешно сохранена")
        } else {
          await this.createPromotion(fd)
          this.$message.success("Акция успешно создана")
          this.$router.push({
            name: "EditPromotion",
            params: { id: this.currentPromotion.id },
          })
        }
        console.log(this.currentPromotion)
      } catch (error) {
        this.$message.error(error.message)
      } finally {
        this.loading = false
      }
    },
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.submitData()

          return false
        } else {
          return false
        }
      })
      return false
    },
    beforeUpload(file) {
      console.log(file)
      this.form.image = file
      //this.uploadImages = [file]

      return false
    },
    onFileRemove() {
      this.form.image = undefined
      this.uploadImages = []
      return true
    },
    handleChange(info) {
      //
      if (info.fileList.length) {
        const fl = [info.fileList.pop()]
        this.uploadImages = fl
      }
    },
    async onDelete() {
      await this.deletePromotion(this.id)
      this.$message.success("Акция успешно удалена")
      this.$router.push({ name: "Promotions" })
    },
    ...mapActions({
      createPromotion: "promotions/createPromotion",
      fetchPromotion: "promotions/fetchPromotion",
      updatePromotion: "promotions/updatePromotion",
      deletePromotion: "promotions/deletePromotion",
    }),
  },
}
</script>

<style lang="scss">
// TODO: change!
.ant-list-vertical .ant-list-item-action {
  margin-top: 0 !important;
}
</style>

<style lang="scss" scoped>
.promotions {
  .extra {
    min-width: 40vw;
  }

  .img {
    max-width: 30vw;
  }
}
</style>
