export default [
  {
    key: 1,
    dealerName: "FIAT ЦЕНТР ИРКУТСК",
    statuslessLinesAmount: "5",
    statuslessLinesPresence: "2",
    statuslessLinesSroch: "1",
    statuslessLinesSklad: "1",
    statuslessLinesOrder: "0",
    statuslessLinesOtherDealers: "1",
    statuslessLinesOther: "0",
  },
  {
    key: 2,
    dealerName: "Делфо",
    statuslessLinesAmount: "0",
    statuslessLinesPresence: "0",
    statuslessLinesSroch: "0",
    statuslessLinesSklad: "0",
    statuslessLinesOrder: "0",
    statuslessLinesOtherDealers: "0",
    statuslessLinesOther: "0",
  },
  {
    key: 3,
    dealerName: "Темп-Авто",
    statuslessLinesAmount: "16",
    statuslessLinesPresence: "10",
    statuslessLinesSroch: "3",
    statuslessLinesSklad: "0",
    statuslessLinesOrder: "1",
    statuslessLinesOtherDealers: "2",
    statuslessLinesOther: "0",
  },
  {
    key: 4,
    dealerName: "Автоцентр ВСК",
    statuslessLinesAmount: "1",
    statuslessLinesPresence: "0",
    statuslessLinesSroch: "0",
    statuslessLinesSklad: "0",
    statuslessLinesOrder: "1",
    statuslessLinesOtherDealers: "0",
    statuslessLinesOther: "0",
  },
  {
    key: 5,
    dealerName: "Интеравтоцентр",
    statuslessLinesAmount: "8",
    statuslessLinesPresence: "4",
    statuslessLinesSroch: "0",
    statuslessLinesSklad: "2",
    statuslessLinesOrder: "0",
    statuslessLinesOtherDealers: "0",
    statuslessLinesOther: "2",
  },
]
