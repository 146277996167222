import createCrudModule from "@/utils/vuex-crud/index.js"
import {
  REPORT_ADJUSTMENTS_SALES,
  REPORT_COLUMNS,
  REPORT_FILTERS,
  REPORT_NOT_DEALER,
  REPORT_VOLUME_SALES,
} from "@/components/reports/dynamic/const"
const mod = createCrudModule({
  parseList: resp => resp.data,
  parseSingle: resp => resp.data,
  resource: "reportdata",
  idAttribute: "code",
  state: {
    searchFilter: {},
    currentType: 1,
    filters: REPORT_FILTERS,
    loading: {
      [REPORT_VOLUME_SALES]: false,
      [REPORT_NOT_DEALER]: false,
      [REPORT_ADJUSTMENTS_SALES]: false,
    },
    line: {
      columns: REPORT_COLUMNS,
    },
  },
  getters: {
    columns: state => state.line.columns[state.currentType],
    loading: state => state.loading[state.currentType],
    filters: state => state.filters[state.currentType],
    currentType: state => state.currentType,
  },
  mutations: {
    FILTER: () => {},
    CLEAR_FILTERS: state => {
      for (let key in state.filters[state.currentType]) {
        state.filters[state.currentType][key] = ""
      }
    },
    SET_FILTER_ITEM(state, payload) {
      state.filters[state.currentType][payload.type] = payload.value
    },
    CHANGE_TYPE: (state, payload) => (state.currentType = payload),
    SET_LOADING: (state, { type, value }) => {
      state.loading[type] = value
    },
    SET_SEARCH_FILTER: (state, payload) => (state.searchFilter = payload),
    CREATE_REPORT: () => {},
  },
})
export const state = mod.state
export const actions = mod.actions
export const mutations = mod.mutations
export const getters = mod.getters
