import createCrudModule from "@/utils/vuex-crud/index.js"
const mod = createCrudModule({
  parseList: resp => resp.data,
  resource: "client",
  state: {
    searchFilter: {},
    filters: {
      external_id: "",
      inn: "",
      legal: "",
    },
    columns: [
      {
        title: "Номер",
        width: 200,
        dataIndex: "external_id",
        align: "center",
      },
      {
        title: "ИНН",
        dataIndex: "legal_info",
        width: 300,
        align: "center",
        scopedSlots: { customRender: "inn" },
      },
      {
        title: "Название организации",
        dataIndex: "legal_info",
        align: "center",
        scopedSlots: { customRender: "name" },
      },
      {
        title: "Действия",
        dataIndex: "action",
        width: 200,

        align: "center",
        scopedSlots: { customRender: "action" },
      },
    ],
  },
  actions: {},
  mutations: {
    FILTER: () => {},
    CLEAR_FILTERS: state => {
      for (let key in state.filters) {
        state.filters[key] = ""
      }
    },
    SET_FILTER_ITEM(state, payload) {
      state.filters[payload.type] = payload.value
    },
    CHANGE_TYPE: (state, payload) => (state.currentType = payload),
    SET_LOADING: (state, { value }) => {
      state.loading = value
    },
    SET_SEARCH_FILTER: (state, payload) => (state.searchFilter = payload),
    CREATE_REPORT: () => {},
  },
  getters: {
    columns: state => state.columns,
    filters: state => state.filters,
  },
})
export const state = mod.state
export const actions = mod.actions
export const mutations = mod.mutations
export const getters = mod.getters
