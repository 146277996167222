import BasketService from "@/services/api/v1/BasketService"
const service = new BasketService()

export const state = {
  basket: null,
  loading: false,
  selected: [],
}
export const getters = {
  selected: state => state.selected,
  basket: state => state.basket,
  count: state =>
    state.basket &&
    state.basket.products.reduce((sum, value) => sum + value.count, 0),
  total: state =>
    state.basket &&
    state.basket.products.reduce(
      (sum, value) => sum + value.count * value.calculatedPrice,
      0
    ),
  loading: state => state.loading,
  products: state => (state.basket ? state.basket.products : []),
  totalWeight: state => {
    let weight = 0
    if (state.basket && state.basket.products) {
      for (const product of state.basket.products) {
        if (product.productRef.attributes.weight) {
          weight += parseFloat(product.productRef.attributes.weight.value)
        }
      }
    }
    return weight
  },
  totalDimensions: state => {
    let dim = 0,
      w = 0,
      h = 0,
      l = 0

    if (state.basket && state.basket.products) {
      for (const product of state.basket.products) {
        w = product.productRef.attributes.width
          ? parseFloat(product.productRef.attributes.width.value)
          : 0
        h = product.productRef.attributes.height
          ? parseFloat(product.productRef.attributes.height.value)
          : 0
        l = product.productRef.attributes.length
          ? parseFloat(product.productRef.attributes.length.value)
          : 0
        dim += w * l * h
      }
    }
    return dim
  },
  maxLen: state => {
    let dim = 0,
      w = 0,
      h = 0,
      l = 0,
      tmp = 0

    if (state.basket && state.basket.products) {
      for (const product of state.basket.products) {
        w = product.productRef.attributes.width
          ? product.productRef.attributes.width.value
          : 0
        h = product.productRef.attributes.height
          ? product.productRef.attributes.height.value
          : 0
        l = product.productRef.attributes.length
          ? product.productRef.attributes.length.value
          : 0
        tmp = Math.max(w, l, h)
        if (dim < tmp) dim = tmp
      }
    }
    return dim
  },
}

export const mutations = {
  SET_BASKET(state, payload) {
    state.basket = payload
  },
  CLEAR_BASKET(state) {
    state.basket = null
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_PRODUCT_FIELD(state, { index, key, value }) {
    state.basket.products[index][key] = value
  },
  SET_PRODUCTS(state, payload) {
    state.basket.products = payload
  },
  SET_SELECTED(state, payload) {
    state.selected = [...payload]
  },
}

export const actions = {
  async getBasket({ commit }) {
    commit("SET_LOADING", true)
    const response = await service.getBasket()

    if (!response) {
      commit("SET_LOADING", false)
      return
    }

    const formattedProducts = items => {
      return items.map(item => ({
        ...item,
        deletemark: false,
        send: false,
        comment: "",
      }))
    }

    const basket = response.data
    basket.products = formattedProducts(basket.products)

    commit("SET_BASKET", basket)
    commit("SET_LOADING", false)
  },
  async addProduct({ commit }, { basket, product, count }) {
    console.log("pp", product, count)
    commit("SET_LOADING", true)
    try {
      const response = await service.addProduct({ ...product, count }, basket)
      commit("SET_LOADING", false)
      if (response) {
        commit("SET_PRODUCTS", response.data.products)
        return true
      }
    } catch (error) {
      commit("SET_LOADING", false)

      return false
    }
  },
  async addProducts({ commit }, { basket, products }) {
    commit("SET_LOADING", true)
    const response = await service.addProducts({ products }, basket)
    commit("SET_LOADING", false)
    if (response) {
      commit("SET_PRODUCTS", response.data.products)
      return true
    }
  },
  async addAllProducts({ commit }, { basket, products }) {
    commit("SET_LOADING", true)
    const response = await service.addProducts({ products }, basket)
    commit("SET_LOADING", false)
    if (response) {
      commit("SET_PRODUCTS", response.data.products)
      return true
    }
  },
  async updateBasket({ commit }, payload) {
    commit("SET_LOADING", true)
    let response

    try {
      response = await service.updateBasket(payload.currentBasket)
    } catch (error) {
      response = error.response
    }

    commit("SET_LOADING", false)

    const formattedProducts = items => {
      return items.map(item => ({
        ...item,
        deletemark: false,
        send: false,
        comment: "",
      }))
    }

    const basket = response.status === 501 ? payload.prevBasket : response.data
    basket.products = formattedProducts(basket.products)

    if (response.status === 501) {
      payload.handleBlur()
    }

    commit("SET_BASKET", basket)
  },
  async clearBasket({ commit }, payload) {
    commit("SET_LOADING", true)

    const responce = await service.clearBasket(payload)
    const basket = responce.data

    commit("SET_LOADING", false)
    commit("SET_BASKET", basket)
  },
  async deleteFromBasket(_, payload) {
    await service.deleteFromBasket(payload)
    return _.dispatch("getBasket")
  },
  async verifyBasket(_, payload) {
    return await service.verifyBasket(payload)
  },
}
