import OrderService from "@/services/api/v1/OrderService"
import createCrudModule from "@/utils/vuex-crud/index.js"
const mod = createCrudModule({
  parseList: resp => resp.data,
  resource: "order",
  state: {
    statuses: [],
    searchFilter: {},
    filters: {
      number: "",
      legaldealer: "",
      warehouse: "",
      legal: "",
      status: "",
      period: "",
      "delivery.type": "",
    },
    columns: [
      {
        title: "№",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Статус",
        dataIndex: "status_name",
        key: "id",
      },
    ],
  },
  mutations: {
    SET_FILTER_ITEM(state, payload) {
      state.filters[payload.type] = payload.value
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    CLEAR_FILTERS(state) {
      state.searchFilter = {}

      for (let key in state.filters) {
        state.filters[key] = ""
      }
    },
    SET_SEARCH_FILTER: (state, payload) => (state.searchFilter = payload),
    FILTER() {},
  },
  actions: {
    async loadStatuses({ state }) {
      state.statuses = (await new OrderService().getStatuses()).data
    },
  },
  getters: {
    filters: state => state.filters,
    statuses(state) {
      return state.statuses
    },
  },
})
export const state = mod.state
export const actions = mod.actions
export const mutations = mod.mutations
export const getters = mod.getters
