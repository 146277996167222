import createCrudModule from "@/utils/vuex-crud/index.js"
import RefundsService from "@/services/api/v1/RefundsService"
const mod = createCrudModule({
  parseList: resp => resp.data,
  parseSingle: resp => resp.data,
  resource: "refunds",
  state: {
    searchFilter: {},
    filters: {
      order_number: "",
      period: "",
      status: "",
    },
    columns: [
      {
        title: "№",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Статус",
        dataIndex: "status_name",
        key: "id",
      },
    ],
  },
  getters: {
    filters: state => state.filters,
  },
  actions: {
    async create(_, formData) {
      return new RefundsService().create(formData)
    },
    async setstatus(_, formData) {
      return new RefundsService().setstatus(formData)
    },
  },
  mutations: {
    SET_FILTER_ITEM(state, payload) {
      state.filters[payload.type] = payload.value
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    CLEAR_FILTERS(state) {
      state.searchFilter = {}

      for (let key in state.filters) {
        state.filters[key] = ""
      }
    },
    SET_SEARCH_FILTER: (state, payload) => (state.searchFilter = payload),
    FILTER() {},
  },
})
export const state = mod.state
export const actions = mod.actions
export const mutations = mod.mutations
export const getters = mod.getters
