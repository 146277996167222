import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class UserService {
                 async auth(login, password) {
                   let name = login
                   return axios.post(`${API_PREFIX}/login`, {
                     email: name,
                     password,
                   })
                 }

                 async resetPassword(email) {
                   return axios.post(`${API_PREFIX}/forgotpassword`, {
                     email,
                   })
                 }

                 async resetPasswordConfirm(resetData) {
                   return axios.post(`${API_PREFIX}/resetpassword`, resetData)
                 }

                 async setNewUser(userInfo) {
                   return axios.post(`${API_PREFIX}/client/1/user`, userInfo)
                 }

                 async setNewDealerUser(userInfo) {
                   return axios.post(`${API_PREFIX}/dealer/user`, userInfo)
                 }

                 async getUser(id) {
                   return axios.get(
                     `${API_PREFIX}/users/${id}?include=dealer,client`
                   )
                 }

                 async deleteUser(id) {
                   return axios.delete(`${API_PREFIX}/users/${id}`)
                 }

                 async blockUser(id) {
                   return axios.post(`${API_PREFIX}/users/${id}/block`)
                 }

                 async getCurrentUser() {
                   return axios.get(`${API_PREFIX}/me`)
                 }
                 async getRoleList() {
                   return axios.get(`${API_PREFIX}/users/rolelist`)
                 }
               }
