/**
 * ADMIN
 */

export default (() => {
  return {
    ADMIN: {
      defaultSelectedId: ["Clients"],
      items: [
        {
          id: "SearchDetails",
          name: "Поиск запчастей",
          icon: "monitor",
          path: "/",
        },
        // {
        //   id: "Basket",
        //   name: "Корзина",
        //   icon: "shopping-cart",
        //   path: "/basket",
        // },
        {
          id: "Orders",
          name: "Заказы",
          icon: "shopping-cart",
          path: "/orders",
        },
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        // {
        //   id: "Promotions",
        //   name: "Акции",
        //   icon: "percentage",
        //   path: "/promotions",
        // },
        // {
        //   id: "PricesCS",
        //   icon: "container",
        //   name: "Прайс-листы ЦС",
        //   path: "/pricescs",
        // },
        // {
        //   id: "DirectoriesCS",
        //   name: "Справочники ЦС",
        //   icon: "folder",
        //   path: "/directoriescs",
        // },
        // {
        //   id: "Reports",
        //   name: "Отчеты",
        //   icon: "bar-chart",
        //   children: {
        //     items: [
        //       {
        //         id: "22",
        //         name: "Период",
        //         path: "/reports/period",
        //       },
        //       {
        //         id: "23",
        //         name: "Динамика",
        //         path: "/reports/dynamic",
        //       },
        //       {
        //         id: "24",
        //         name: "Период-группа",
        //         path: "/reports/period-group",
        //       },
        //       {
        //         id: "25",
        //         name: "Динамика-группа",
        //         path: "/reports/dynamic-group",
        //       },
        //     ],
        //   },
        // },
        {
          id: "Dealer",
          name: "Дилеры",
          icon: "exclamation-circle",
          path: "/dealers",
        },
        // {
        //   id: "21",
        //   name: "Экспорт",
        //   icon: "share-alt",
        //   path: "/export",
        // },
        {
          id: "Prices",
          name: "Прайс-листы",
          icon: "container",
          path: "/prices",
        },
        {
          id: "Clients",
          name: "Клиенты",
          icon: "team",
          roles: ["Dealer"],
          children: {
            items: [
              {
                id: "Clients",
                name: "Список клиентов",
                path: "/clients",
              },
            ],
          },
        },

        {
          id: "AllUsers",
          name: "Пользователи системы",
          icon: "team",
          path: "/allusers",
        },
        // {
        //   id: "4",
        //   name: "Прайсы",
        //   icon: "container",
        //   children: {
        //     items: [
        // {
        //   id: "LocalPrices",
        //   name: "Локальные прайс-листы",
        //   path: "/localprices",
        // },
        // {
        //   id: "ClientPrices",
        //   name: "Клиентские прайс-листы",
        //   path: "/clientprices",
        // },
        // {
        //   id: "Markups",
        //   name: "Наценки",
        //   path: "/markups",
        // },
        //   ],
        // },
        // },
        // {
        //   id: "Delivery",
        //   name: "Доставка",
        //   icon: "exclamation-circle",
        //   path: "/delivery",
        // },
      ],
    },
  }
})()
