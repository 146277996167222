import createCrudModule from "@/utils/vuex-crud/index.js"

const mod = createCrudModule({
  parseList: resp => resp.data,
  parseSingle: resp => resp.data,
  resource: "cataloguploadtasks",
  state: {
    searchFilter: {},
    filters: {
      status: "",
      period: "",
      "catalog.dealer_id": "",
    },
    columns: [
      {
        title: "Код",
        dataIndex: "catalog.dealer.external_id",
        width: 120,
      },
      {
        title: "Наименование",
        dataIndex: "original_file_name",
        width: 150,
      },
      {
        title: "Дата загрузки",
        dataIndex: "created_at",
        align: "center",
        scopedSlots: { customRender: "created_at" },
        width: 120,
      },
      {
        title: "Метод загрузки",
        dataIndex: "upload_type",
        align: "center",
        scopedSlots: { customRender: "upload_type" },
        width: 120,
      },
      {
        title: "Процент загрузки",
        dataIndex: "progress",
        align: "center",
        scopedSlots: { customRender: "progress" },
        key: "progress",
        width: 70,
      },
      {
        title: "Статус прайс-листа",
        dataIndex: "status",
        align: "center",
        width: 120,
        scopedSlots: { customRender: "status" },
      },
      {
        title: "Действие",
        key: "media",
        dataIndex: "media",
        align: "center",
        scopedSlots: { customRender: "download" },
        width: 120,
      },
      /* {
        title: "Действия",
        fixed: "right",
        dataIndex: "operation",
        scopedSlots: { customRender: "operation" },
        width: 110,
      },*/
    ],
  },
  getters: {
    filters: state => state.filters,
    columns: state => state.columns,
  },
  mutations: {
    SET_FILTER_ITEM(state, payload) {
      state.filters[payload.type] = payload.value
    },
    CLEAR_FILTERS(state) {
      state.searchFilter = {}

      for (let key in state.filters) {
        state.filters[key] = ""
      }
    },
    SET_SEARCH_FILTER: (state, payload) => (state.searchFilter = payload),
    FILTER() {},
    UPLOAD_PRICE_LIST() {},
  },
})
export const state = mod.state
export const actions = mod.actions
export const mutations = mod.mutations
export const getters = mod.getters
