/**
 * IMPORTER_OPERATOR
 */

export default (() => {
  return {
    IMPORTER_OPERATOR: {
      defaultSelectedId: ["Promotions"],
      items: [
        // {
        //   id: "Promotions",
        //   name: "Акции",
        //   icon: "percentage",
        //   path: "/promotions",
        // },
        {
          id: "Clients",
          name: "Клиенты",
          icon: "team",
          path: "/clients",
        },
        {
          id: "Price",
          name: "Прайс-листы",
          icon: "container",
          path: "/prices",
        },
      ],
    },
  }
})()
