export default [
  {
    id: 1,
    key: 1,
    dealer: "INCHCAPE CENTER",
    address: "ул. 2-я Магистральная, 18/22, Москва, 123007",
    phone: "+7 (495) 266-25-91",
    site: "landrover.inchcape.ru",
  },
  {
    id: 2,
    key: 2,
    dealer: "АВИЛОН",
    address: "Волгоградский пр-т 41/1, Москва, 109316",
    phone: "+7 (495) 730-44-46",
    site: "landrover-avilon.ru",
  },
  {
    id: 3,
    key: 3,
    dealer: "КУНЦЕВО",
    address: "ул. Горбунова, 14, Москва, 121596",
    phone: "+7 (495) 118-20-40",
    site: "landrover-kuntsevo.ru",
  },
  {
    id: 4,
    key: 4,
    dealer: "АВТОПАССАЖ ПРЕМИУМ",
    address: "Варшавское шоссе, 138 А, Москва, 117519",
    phone: "+7 (495) 414-21-57",
    site: "lr.autopassage.ru",
  },
  {
    id: 5,
    key: 5,
    dealer: "МЭЙДЖОР МКАД 47",
    address: "47 км МКАД, Москва, 142784",
    phone: "+7 (495) 414-24-78",
    site: "major-landrover.ru",
  },
  {
    id: 6,
    key: 6,
    dealer: "АВТОДОМ",
    address: "85 км МКАД, Москва, 141031",
    phone: "+7 (495) 118-21-52",
    site: "landrover-avtodom.ru",
  },
  {
    id: 7,
    key: 7,
    dealer: "МЭЙДЖОР МКАД 18",
    address: "ул. Алексеевская 12, МКАД 18, Дзержинский, 140090",
    phone: "+7 (495) 414-22-54",
    site: "major-landrover.ru",
  },
  {
    id: 8,
    key: 8,
    dealer: "РОЛЬФ ЯСЕНЕВО",
    address: "40-й км МКАД (внешняя сторона), вл.1, стр.2, Москва, 142770",
    phone: "+7 (495) 118-44-67",
    site: "landroveryasenevo.ru",
  },
  {
    id: 9,
    key: 9,
    dealer: "INCHCAPE SOUTH",
    address: "Строение 10-Ю, Булатниковское, Московская обл, 142718",
    phone: "+7 (495) 266-21-51",
    site: "landrover.inchcape.ru",
  },
  {
    id: 10,
    key: 10,
    dealer: "МЭЙДЖОР НОВАЯ РИГА",
    address: "25 км трассы Балтия, Михалково, Московская обл, 143420",
    phone: "+7 (495) 414-23-94",
    site: "major-landrover.ru",
  },
  {
    id: 11,
    key: 11,
    dealer: "АВТОБИОГРАФИЯ ЦЕНТР",
    address: "Обводного канала наб., д.72, Санкт-Петербург, 196084",
    phone: "+7 (812) 467-46-93",
    site: "abg-landrover.ru",
  },
  {
    id: 12,
    key: 12,
    dealer: "АВТОПРАЙМ",
    address: "Полюстровский пр-т, 79., Санкт-Петербург, 195197",
    phone: "+7 (812) 467-46-24",
    site: "landrover-rolf.ru",
  },
  {
    id: 13,
    key: 13,
    dealer: "РОЛЬФ НЕВА",
    address: "Октябрьская набережная 8/3, Санкт-Петербург, 193091",
    phone: "+7 (812) 467-45-84",
    site: "landrover-rolf.ru",
  },
  {
    id: 14,
    key: 14,
    dealer: "ОМЕГА-ПРЕМИУМ",
    address: "Школьная, 75., Санкт-Петербург, 197348",
    phone: "+7 (812) 467-43-71",
    site: "omega-premium.ru",
  },
  {
    id: 15,
    key: 15,
    dealer: "АВТОГРАД ПЛЮС",
    address: "Ленинский проспект, 156, Воронеж, Воронежская обл., 394042",
    phone: "+7 (473) 200-67-14",
    site: "avtogradplus.ru",
    isActive: true,
  },
  {
    id: 16,
    key: 16,
    dealer: "АРКОНТ",
    address: "ул. Землячки 25, Волгоград, 400117",
    phone: "+7 (844) 245-97-14",
    site: "landrover-arkont.ru",
  },
  {
    id: 17,
    key: 17,
    dealer: "ЛАКИ МОТОРС",
    address: "ул.Металлургов 65А, Екатеринбург, 620043",
    phone: "+7 (343) 247-23-29",
    site: "lr.jlr-lm.ru",
  },
  {
    id: 18,
    key: 18,
    dealer: "АВТОПЛЮС",
    address: "Начдива Онуфриева 57А, Екатеринбург, 620146",
    phone: "+7 (343) 247-23-49",
    site: "landrover-autoplus.ru",
  },
  {
    id: 19,
    key: 19,
    dealer: "АЛЬБИОН-МОТОРС НСК",
    address: "Станционная ул., д.89, Новосибирск, 630121",
    phone: "+7 (383) 312-03-97",
    site: "albion-motors.ru",
  },
  {
    id: 20,
    key: 20,
    dealer: "АВТОГРАД ПРЕСТИЖ",
    address: "Республики 280, Тюмень, Ханты-Мансийский, 625014",
    phone: "+7 (345) 228-39-96",
    site: "lr-agrad.ru",
  },
  {
    id: 21,
    key: 21,
    dealer: "БОВИД АВТО",
    address: "Ленина проспект 26А стр.2, Челябинск, Челябинская, 454007",
    phone: "+7 (351) 202-02-48",
    site: "bovidlandrover.ru",
  },
  {
    id: 22,
    key: 22,
    dealer: "ПЛАНЕТА АВТО",
    address: "Братьев Кашириных 143, Челябинск, Челябинская обл, 454128",
    phone: "+7 (351) 799-59-20",
    site: "landrover.planeta-avto.ru",
  },
]
