const PASSWORD_PATTERN = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/g
const NUMBER_PATTERN = /[^0-9]/g
// eslint-disable-next-line no-useless-escape
const PHONE_PATTERN = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/g

export const validatePassword = value => {
  return PASSWORD_PATTERN.test(value) || !value
}

export const validateNumber = value => {
  return NUMBER_PATTERN.test(value) || !value
}

export const validatePhone = value => {
  return PHONE_PATTERN.test(value) || !value
}
