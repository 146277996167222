import * as paymentService from "@/services/api/v1/PaymentService"

export const state = {
  paymentGroups: [],
}

export const getters = {
  paymentGroups: state => state.paymentGroups,
}

export const mutations = {
  SET_PAYMENT_GROUPS(state, payload) {
    state.paymentGroups = payload
  },
}

export const actions = {
  async getPaymentGroups({ commit }) {
    const response = await paymentService.getPaymentGroups()

    if (!response?.data) return

    commit("SET_PAYMENT_GROUPS", response.data.data)
    return response.data
  },
  async setPaymentGroups(_, ar) {
    return paymentService.setPaymentGroups(ar)
  },
  clearPrices({ commit }) {
    commit("SET_PAYMENT_GROUPS", [])
  },
}
