import DealerService from "@/services/api/v1/DealerService"
const services = new DealerService()

import CreateDealerService from "@/services/api/v1/CreateDealerService"
const createService = new CreateDealerService()

export const state = {
  dealers: [],
  dealerUsers: [],
  currentDealer: null,
  columns: [
    {
      title: "Дилер",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Код",
      dataIndex: "external_id",
      key: "external_id",
    },
    {
      title: "Адрес",
      dataIndex: "legal_info.address",
      key: "address",
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Сайт",
      dataIndex: "site",
      key: "site",
    },
  ],
  columnsDealerUsers: [
    {
      title: "Имя пользователя",
      dataIndex: "name",

      filterable: true,
    },
    {
      title: "Почта",
      dataIndex: "email",

      filterable: true,
    },
    {
      title: "Компания",
      dataIndex: "company",
      scopedSlots: { customRender: "company" },
    },
    {
      title: "Роль",
      dataIndex: "role.name",
    },
    {
      title: "Действия",
      dataIndex: "operation",
      scopedSlots: { customRender: "operation" },
      className: "column-action",
      width: 170,
    },
  ],
  createForm: {
    name: "",
    paymentGroup: {
      id: "",
      name: "",
      ordering: "",
    },
    userId: "",
    headName: "",
    headPosition: "",
    refund_days: "",
    exteranl_id: "",
    legalInfo: {
      name: "",
      paymentAccount: "",
      bankId: "",
      bankName: "",
      correspondedAccount: "",
      underlyingDocument: "",
      inn: "",
      kpp: "",
      ogrn: "",
      okpo: "",
      address: "",
      physicalAddress: "",
      phone: "",
      site: "",
    },
    contacts: [
      {
        type: "Взаиморасчеты",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Отправка прайс-листов",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Руководитель",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Заказы",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Возвраты",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Обновление паролей",
        name: "",
        phone: "",
        email: "",
      },
    ],
  },
}

export const mutations = {
  SET_DEALERS(state, payload) {
    state.dealers = payload
  },
  SET_CURRENT_DEALER(state, payload) {
    state.currentDealer = payload
  },
  SET_CREATE_FORM(state, payload) {
    state.createForm = payload
  },
  SET_CREATE_FORM_FIELD(state, payload) {
    state.createForm[payload.name] = payload.value
  },
  SET_CREATE_FORM_CONTACT_FIELD(state, payload) {
    state.createForm.contacts[payload.index][payload.field] = payload.value
  },
  SET_CREATE_DEALER_FORM_ERROR(state, payload) {
    state.createFormError = payload
  },
  CLEAR_CREATE_DEALER_FORM_ERROR(state) {
    state.createFormError = null
  },
  SET_DEALER_USERS(state, payload) {
    state.dealerUsers = payload
  },
  DEL_DEALER_USER_ITEM(state, payload) {
    state.dealerUsers = state.dealerUsers.filter(el => el.id !== payload)
  },
}

export const actions = {
  async fetchDealers({ commit }) {
    const dealers = await services.getDealers()
    const responce = dealers.data.items
    commit("SET_DEALERS", responce)
  },
  async fetchDealerById({ commit }, payload) {
    const dealer = await services.getSingleDealer(parseInt(payload))
    const responce = await dealer.data
    commit("SET_CURRENT_DEALER", responce)
  },
  async updateDealer({ getters }) {
    const dealerFormData = { ...getters.createForm }
    const contacts = dealerFormData.contacts.filter(item => {
      if (item && (item.name || item.phone || item.email)) {
        return item
      }
    })
    dealerFormData.contacts = contacts
    await createService.updateDealer(dealerFormData)
  },
  async createDealer({ commit, getters }, userResp) {
    const dealerFormData = getters.createForm
    const contacts = dealerFormData.contacts.filter(item => {
      if (item && (item.name || item.phone || item.email)) {
        return item
      }
    })
    dealerFormData.user = userResp
    dealerFormData.contacts = contacts
    return new Promise((resolve, reject) => {
      commit("CLEAR_CREATE_DEALER_FORM_ERROR")
      createService
        .createDealer(dealerFormData)
        .then(resp => resolve(resp))
        .catch(error => {
          reject(error.response)
        })
    })
  },
  async fetchDealerUsers({ commit }) {
    const dealerUsers = await services.getDealerUsers()
    const responce = dealerUsers.data.data
    commit("SET_DEALER_USERS", responce)
  },
  async deleteDealerUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("DEL_DEALER_USER_ITEM", id)
      services
        .delSingleDealerUsers(id)
        .then(() => resolve())
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  },
  async updateDealerUser(_, user) {
    return new Promise((resolve, reject) => {
      services
        .updateDealerUser(user.id, user)
        .then(() => resolve())
        .catch(() => {
          reject()
        })
    })
  },
}

export const getters = {
  dealers: state => state.dealers,
  currentDealer: state => state.currentDealer,
  columns: state => state.columns,
  columnsDealerUsers: state => state.columnsDealerUsers,
  createForm: state => state.createForm,
  data: state => state.createForm.contacts,
  dealerUsers: state => state.dealerUsers,
}
