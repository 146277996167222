/**
 * NSTO_ORDER_OPERATOR
 */

export default (() => {
  return {
    NSTO_ORDER_OPERATOR: {
      defaultSelectedId: ["SearchDetails"],
      items: [
        {
          id: "SearchDetails",
          name: "Поиск запчастей",
          icon: "monitor",
          path: "/",
        },
        {
          id: "Basket",
          name: "Корзина",
          icon: "shopping-cart",
          path: "/basket",
        },
        {
          id: "Orders",
          name: "Заказы",
          icon: "shopping-cart",
          children: {
            items: [
              {
                id: "45",
                name: "Список",
                path: "/orders",
              },
              {
                id: "55",
                name: "Корректировки",
                path: "/orders/corrections",
              },
            ],
          },
        },
        /*{
          id: "BuyerDocuments",
          name: "Документы",
          icon: "file-text",
          path: "/buyer/documents",
        },*/
      ],
    },
  }
})()
