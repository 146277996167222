import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class DealerService {
  async getDealers() {
    return axios.get(`${API_PREFIX}/dealer`)
  }

  async bindDealer(dealerUpdatedInfo) {
    return axios.put(`${API_PREFIX}/dealer`, dealerUpdatedInfo)
  }

  async getSingleDealer(id) {
    return axios.get(`${API_PREFIX}/dealer/${id}`)
  }
  async getDealerBuyers(id) {
    return axios.get(`${API_PREFIX}/dealer/${id}/clients`)
  }

  async getDealerUsers() {
    return axios.get(`${API_PREFIX}/dealer/users?page=1&per_page=50`)
  }
  async delSingleDealerUsers(id) {
    return axios.delete(`${API_PREFIX}/dealer/users/${id}`)
  }
  async updateDealerUser(id, user) {
    return axios.patch(`${API_PREFIX}/dealer/users/${id}`, user)
  }
}
