<template>
  <a-breadcrumb class="breadcrumb">
    <a-breadcrumb-item v-for="route in breadcrumbs" :key="route.id">
      <router-link :to="route.path">{{ route.name }}</router-link>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  name: "Breadcrumb",
  data: () => ({
    routes: [
      {
        id: 1,
        name: "Главная",
        code: "Home",
        path: "/",
      },
      {
        id: 2,
        name: "Заказы",
        code: "Orders",
        path: "/orders",
      },
      {
        id: 3,
        name: "Клиенты",
        code: "Clients",
        path: "/clients",
      },
      {
        id: 4,
        name: "Отчеты",
        code: "Reports",
        path: "/reports",
      },
      // {
      //   id: 5,
      //   name: "Локальные прайс-листы",
      //   code: "LocalPrices",
      //   path: "/localprices",
      // },
      {
        id: 6,
        name: "Акции",
        code: "Promotions",
        path: "/promotions",
      },
      {
        id: 7,
        name: "Поиск запчастей",
        code: "SearchDetails",
        path: "/",
      },
      {
        id: 8,
        name: "Документы",
        code: "Documents",
        path: "/documents",
      },
      {
        id: 9,
        name: "Дилеры",
        code: "Dealer",
        path: "/dealers",
      },
      {
        id: 10,
        name: "Информация о клиенте",
        code: "ClientInfo",
        path: "#",
      },
      // {
      //   id: 11,
      //   name: "Наценки",
      //   code: "Markups",
      //   path: "/markups",
      // },
      {
        id: 12,
        name: "Регистрация нового клиента",
        code: "CreateNewClient",
        path: "/createclient?name=&inn=",
      },
      // {
      //   id: 13,
      //   name: "Прайс-листы ЦС",
      //   code: "PricesCS",
      //   path: "/pricescs",
      // },
      {
        id: 14,
        name: "Рассылка прайс-листов",
        code: "PricesDelivery",
        path: "/pricesdelivery",
      },
      // {
      //   id: 15,
      //   name: "Справочники ЦС",
      //   code: "DirectoriesCS",
      //   path: "/directoriescs",
      // },
      {
        id: 16,
        name: "Локальный справочник",
        code: "Directories",
        path: "/directories",
      },
      {
        id: 17,
        name: "Список разрешенных брендов",
        code: "AllowedBrandsList",
        path: "/allowedbrands",
      },
      {
        id: 18,
        name: "Настройка локального прайса",
        code: "LocalPricesSettings",
        path: "#",
      },
      {
        id: 19,
        name: "Оформление заказа",
        code: "OrderRegistation",
        path: "/orderregistration",
      },
      {
        id: 20,
        name: "Корзина",
        code: "Basket",
        path: "/basket",
      },
      // {
      //   id: 21,
      //   name: "Доставка",
      //   code: "Delivery",
      //   path: "/delivery",
      // },
      // {
      //   id: 22,
      //   name: "Экспорт",
      //   code: "Export",
      //   path: "/export",
      // },
      {
        id: 23,
        name: "Прайсы",
        code: "Prices",
        path: "/prices",
      },
      {
        id: 24,
        name: "Дилер",
        code: "BuyersDealer",
        path: "/buyersdealer",
      },
      {
        id: 25,
        name: "Период",
        code: "ReportsPeriod",
        path: "/reports/period",
      },
      {
        id: 26,
        name: "Период - группа",
        code: "ReportsPeriodGroup",
        path: "/reports/period-group",
      },
      {
        id: 27,
        name: "Динамика",
        code: "ReportsDynamic",
        path: "/reports/dynamic",
      },
      {
        id: 28,
        name: "Динамика - группа",
        code: "ReportsDynamicGroup",
        path: "/reports/dynamic-group",
      },
      {
        id: 28,
        name: "Оформление заказа",
        code: "OrderRegistration",
        path: "/orderregistration",
      },
      {
        id: 29,
        name: "Информация о заказе",
        code: "Order",
        path: "/order",
      },
      {
        id: 30,
        name: "Создание акции",
        code: "PromotionCreate",
        path: "/promotions/create",
      },
      // {
      //   id: 31,
      //   name: "Клиентские прайс-листы",
      //   code: "ClientPrices",
      //   path: "/clientprices",
      // },
    ],
    breadcrumbs: [],
  }),
  watch: {
    $route() {
      const route = this.routes.find(item => item.code === this.$route.name)
      if (route && this.$route.name === "ClientInfo") {
        this.breadcrumbs = [this.routes[0], this.routes[2], route]
      } else if (
        (route && this.$route.name === "ReportsPeriod") ||
        (route && this.$route.name === "ReportsPeriodGroup") ||
        (route && this.$route.name === "ReportsDynamic") ||
        (route && this.$route.name === "ReportsDynamicGroup")
      ) {
        this.breadcrumbs = [this.routes[0], this.routes[3], route]
      } else if (route) {
        this.breadcrumbs =
          route.code === "Home" ? [this.routes[0]] : [this.routes[0], route]
      } else {
        console.log("Неизветный тип роута для breadcrumbs", this.$route.name)
      }
    },
  },
}
</script>

<style scoped>
.breadcrumb {
  margin: 16px 5px;
}
</style>
