import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class CreateClientService {
  async createClient(createForm) {
    const contacts = createForm.contacts.filter(
      el => el.name !== "" && el.phone !== "" && el.email !== ""
    )

    let data = {
      name: createForm.user.name,
      headName: createForm.user.name,
      headPosition: createForm.headPosition,
      dealer_id: createForm.user.dealer_id,
      legalInfo: {
        name: createForm.legalInfoName,
        paymentAccount: createForm.legalInfoPaymentAccount,
        bankId: createForm.legalInfoBankId,
        bankName: createForm.legalInfoBankName,
        correspondedAccount: createForm.legalInfoCorrespondedAccount,
        underlyingDocument: createForm.legalInfoUnderlyingDocument,
        inn: createForm.legalInfoInn,
        kpp: createForm.legalInfoKpp,
        ogrn: createForm.legalInfoOgrn,
        okpo: createForm.legalInfoOkpo,
        address: createForm.legalInfoAddress,
        physicalAddress: createForm.legalInfoPhysicalAddress,
        phone: "d",
        site: "w",
      },
      user: createForm.user,
    }

    if (contacts.length) {
      data.contacts = contacts
    }

    if (createForm.paymentGroup.id) {
      data.paymentGroupId = createForm.paymentGroup.id
    }

    return axios.post(`${API_PREFIX}/client`, data)
  }
  async bindClient(createForm) {
    return axios.post(`${API_PREFIX}/bindclienttodealer`, createForm)
  }

  async setFinanceAccountAmount(payloads) {
    return axios.post(
      `${API_PREFIX}/client/${payloads.clientId}/account-amount`,
      payloads
    )
  }
}
