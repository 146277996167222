/**
 * NSTO_OPERATOR
 */

export default (() => {
  return {
    NSTO_OPERATOR: {
      defaultSelectedId: ["Promotions"],
      items: [
        // {
        //   id: "Promotions",
        //   name: "Акции",
        //   icon: "percentage",
        //   path: "/promotions",
        // },
      ],
    },
  }
})()
