export default [
  {
    key: 1,
    dealer: "АВТОГРАД ПРЕСТИЖ",
    january: 75,
    february: 90,
    march: 117,
    april: 39,
    may: 10,
    june: 14,
    july: 117,
    august: 80,
    september: 85,
    october: 69,
    november: 62,
  },
  {
    key: 2,
    dealer: "БОВИД АВТО",
    january: 147,
    february: 118,
    march: 207,
    april: 80,
    may: 48,
    june: 73,
    july: 129,
    august: 136,
    september: 157,
    october: 129,
    november: 103,
  },
  {
    key: 3,
    dealer: "АВТОГРАД ПЛЮС",
    january: 156,
    february: 196,
    march: 350,
    april: 154,
    may: 46,
    june: 80,
    july: 176,
    august: 252,
    september: 368,
    october: 395,
    november: 275,
  },
  {
    key: 4,
    dealer: "ПЛАНЕТА АВТО",
    january: 20,
    february: 30,
    march: 40,
    april: 25,
    may: 30,
    june: 27,
    july: 40,
    august: 47,
    september: 42,
    october: 20,
    november: 29,
  },
  {
    key: 5,
    dealer: "АРКОНТ",
    january: 14,
    february: 20,
    march: 33,
    april: 20,
    may: 15,
    june: 35,
    july: 45,
    august: 47,
    september: 40,
    october: 21,
    november: 64,
  },
]
