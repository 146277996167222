/**
 * NSTO_ADMIN
 */

export default (() => {
  return {
    NSTO_ADMIN: {
      defaultSelectedId: ["SearchDetails"],
      items: [
        {
          id: "SearchDetails",
          name: "Поиск запчастей",
          icon: "monitor",
          path: "/",
        },
        {
          id: "Orders",
          name: "Заказы",
          icon: "shopping-cart",
          children: {
            items: [
              {
                id: "45",
                name: "Список",
                path: "/orders",
              },
              {
                id: "55",
                name: "Корректировки",
                path: "/orders/corrections",
              },
            ],
          },
        },
        {
          id: "Basket",
          name: "Корзина",
          icon: "shopping-cart",
          path: "/basket",
        },
        {
          id: "Refunds",
          name: "Возвраты",
          icon: "shopping-cart",
          children: {
            items: [
              {
                id: "RefundCreate",
                name: "Создать",
                path: "/refundscreate",
              },
              {
                id: "RefundItems",
                name: "Список",
                path: "/refunds",
              },
            ],
          },
        },
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        // {
        //   id: "Promotions",
        //   name: "Акции",
        //   icon: "percentage",
        //   path: "/promotions",
        // },
        {
          id: "Sysusers",
          name: "Пользователи системы",
          icon: "team",
          path: "/client/createuser",
        },
        /*{
          id: "BuyerDocuments",
          name: "Документы",
          icon: "file-text",
          path: "/buyer/documents",
        },*/
      ],
    },
  }
})()
