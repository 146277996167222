/* eslint-disable spaced-comment */
import moment from 'moment'
const intlf = new Intl.NumberFormat('ru-RU', {
  //style: 'currency',
  // currency: 'RUB'
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})
export default {
  filters: {
    fdate(value) {
      return moment(value).format('DD.MM.YYYY')
    },
    ftime(value) {
      return moment(value).format('HH:mm')
    },
    fdatetime(value) {
      return moment(value).format('DD.MM.YYYY HH:mm')
    },
    fcurrency(val) {
      return intlf.format(val)
    }
  }
}
