import UserService from "@/services/api/v1/UserService"
import createCrudModule from "@/utils/vuex-crud/index.js"
const mod = createCrudModule({
  parseList: resp => resp.data,
  parseSingle: resp => resp.data,
  resource: "users",
  state: {
    searchFilter: {},
  },
  actions: {
    async blockUser(_, { id }) {
      return await new UserService().blockUser(id)
    },
    async deleteUser(_, { id }) {
      return await new UserService().deleteUser(id)
    },

    async getRoleList() {
      const data = await new UserService().getRoleList()
      return data.data
    },

    async getUser(_, { id }) {
      const { data } = await new UserService().getUser(id)

      return data.data
    },
  },
})
export const state = mod.state
export const actions = mod.actions
export const mutations = mod.mutations
export const getters = mod.getters
