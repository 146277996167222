import { DummyApiService } from "@/services"
const service = new DummyApiService()

export const state = {
  data: [],
  columns: [
    {
      title: "Наименование",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Дата подключения",
      dataIndex: "connectedAt",
      key: "connectedAt",
    },
    {
      title: "Заказы, всего",
      children: [
        {
          title: "Сумма",
          dataIndex: "orders_sum",
          key: "orders_sum",
        },
        {
          title: "Линии",
          dataIndex: "orders_lines",
          key: "orders_lines",
        },
      ],
    },
    {
      title: "Отказы",
      children: [
        {
          title: "Сумма",
          dataIndex: "decline_sum",
          key: "decline_sum",
        },
        {
          title: "Линии",
          dataIndex: "decline_lines",
          key: "decline_lines",
        },
      ],
    },
    {
      title: "Подтвержденные заказы",
      children: [
        {
          title: "Сумма",
          dataIndex: "approved_sum",
          key: "approved_sum",
        },
        {
          title: "Линии",
          dataIndex: "approved_lines",
          key: "approved_lines",
        },
      ],
    },
    {
      title: "Заказы в работе",
      children: [
        {
          title: "Сумма",
          dataIndex: "in_work_sum",
          key: "in_work_sum",
        },
        {
          title: "Линии",
          dataIndex: "in_work_lines",
          key: "in_work_lines",
        },
      ],
    },
    {
      title: "Отгруженные заказы",
      children: [
        {
          title: "Сумма",
          dataIndex: "departed_sum",
          key: "departed_sum",
        },
        {
          title: "Линии",
          dataIndex: "departed_lines",
          key: "departed_lines",
        },
      ],
    },
    {
      title: "Коэффициент подтверждения",
      dataIndex: "approveKef",
      key: "approveKef",
    },
  ],
}

export const mutations = {
  SET_DATA: (state, payload) => (state.data = payload),
}

export const actions = {
  fetchData: async ({ commit }) => {
    const list = await service.getReportBuyersData()
    commit("SET_DATA", list)
  },
}

export const getters = {
  data: state => state.data,
  columns: state => state.columns,
}
