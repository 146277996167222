/* eslint-disable no-shadow */
import Vue from "vue"
import * as VeeValidate from "vee-validate"
import { ValidationProvider, ValidationObserver } from "vee-validate"
import ru from "../locales/ru"
import { localize, extend } from "vee-validate"
import {
  required,
  email,
  min,
  confirmed,
  max,
  double,
  min_value,
  max_value,
  regex,
} from "vee-validate/dist/rules"

// The types of validators used in the project
extend("decimal", {
  params: ["decimals", "separator"],
  validate: (value, { decimals = "*", separator = "." } = {}) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      }
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      }
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    )

    return {
      valid: regex.test(value),
    }
  },
  message: "The {_field_} field must contain only decimal values",
})
extend("currency", {
  params: ["decimals", "separator"],
  validate: (value, { decimals = "2", separator = "." } = {}) => {
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      }
    }
    value = `${value}`
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      }
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    )

    return {
      valid: regex.test(value),
    }
  },
  message: "{_field_} must be correct currency value",
})
extend("regex", { ...regex, message: "No valid {_field_}" })
extend("max", max)
extend("max_value", max_value)
extend("min_value", min_value)
extend("double", double)
extend("required", required)
extend("confirmed", confirmed)
extend("email", email)
extend("min", min)
extend("url", {
  validate: str => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ) // fragment locator
    return !!pattern.test(str)
  },
})

localize({
  ru,
})
localize("ru")
//localize('ru');
Vue.use(VeeValidate)
Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)
export default VeeValidate
