/**
 * IMPORTER_ANALYST
 */

export default (() => {
  return {
    IMPORTER_ANALYST: {
      defaultSelectedId: ["Reports"],
      items: [
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        {
          id: "Clients",
          name: "Клиенты",
          icon: "team",
          path: "/clients",
        },
        {
          id: "Price",
          name: "Прайс-листы",
          icon: "container",
          path: "/prices",
        },
      ],
    },
  }
})()
