<template>
  <a-layout id="app">
    <Slider @hide-menu="hideMenu" />

    <a-layout :style="{ marginLeft: marginLeftLayout, transition: '.3s' }">
      <Header v-show="false" />
      <a-layout-content class="container">
        <h1 v-show="title" class="page-title">{{ title }}</h1>
        <Breadcrumb />
        <div class="content">
          <router-view />
        </div>
      </a-layout-content>
      <a-layout-footer>
        <Footer />
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>

<script>
import "moment/locale/ru"

import moment from "moment"

import Header from "../modules/Header"
import Footer from "../modules/Footer"
import Slider from "../modules/Slider"
import Breadcrumb from "../components/shared/breadcrumb/Breadcrumb"
export default {
  components: {
    Header,
    Footer,
    Slider,
    Breadcrumb,
  },
  mounted() {
    this.getMenuValues()
  },
  data() {
    return {
      moment,
      marginLeftLayout: "250px",
    }
  },
  methods: {
    getMenuValues() {
      const shownMenu = JSON.parse(localStorage.getItem("shownMenu"))

      if (shownMenu === false) {
        this.marginLeftLayout = "0"
      }
    },
    hideMenu(shownMenu) {
      if (shownMenu) {
        this.marginLeftLayout = "0"
      } else {
        this.marginLeftLayout = "250px"
      }
    },
  },
  computed: {
    title() {
      return this.$route.meta.title || ""
    },
  },
}
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
  background: rgba(255, 255, 255, 0.2);
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  font-size: 16px;
  min-height: 100vh;
}

.container {
  margin: 0 16px;
}

.content {
  padding: 24px 5px !important;
  background: #eff2f5 !important;
  min-height: 600px !important;
}

.page-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  margin: 15px 4px -10px;
}

.content-container {
  border-radius: 6px;
  background-color: #fff;
  padding: 25px;
  width: 100%;
  &:not(:first-child) {
    margin-top: 25px;
  }
}
</style>
