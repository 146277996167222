/* eslint-disable spaced-comment */
import i18n from "@/plugins/i18n"
import * as types from "@/store/mutation-types"
import { isPast, format, parseISO } from "date-fns"
import { store } from "@/store/store"
import { es, fr, zhCN, uk } from "date-fns/locale"
import axios from "axios"
import fileDownload from "js-file-download"
import { validatePassword } from "@/utils/validate"

const localesDateFns = {
  es,
  fr,
  cn: zhCN,
  ua: uk,
}
export const cRound = summ => {
  return Math.round((summ + Number.EPSILON) * 100) / 100
}
// v1

export const getFormat = (date, formatStr) => {
  return format(parseISO(date), formatStr, {
    locale: localesDateFns[window.__localeId__],
  })
}

export const formatErrorMessages = (translationParent, msg) => {
  const errorArray = []
  // Check for error msgs
  if (msg !== null) {
    const json = JSON.parse(JSON.stringify(msg))
    // If error message is an array, then we have mutiple errors
    if (Array.isArray(json)) {
      json.map(error => {
        errorArray.push(i18n.t(`${translationParent}.${error.msg}`))
      })
    } else {
      // Single error
      errorArray.push(i18n.t(`${translationParent}.${msg}`))
    }
    return errorArray
  }
  // all good, return null
  return null
}
export const addSearchFieldToPayload = (payload, key, str) => {
  payload[`filter[${key}]`] = str
  return payload
}
export const buildPayloadPagination = (pagination, search) => {
  const { page, itemsPerPage } = pagination
  let { sortDesc, sortBy } = pagination

  // When sorting you always get both values
  if (sortBy.length === 1 && sortDesc.length === 1) {
    // Gets order
    sortDesc = sortDesc[0] === true ? -1 : 1
    // Gets column to sort on
    sortBy = sortBy ? sortBy[0] : ""
  }

  let query = {}

  // If search and fields are defined
  if (search) {
    query = {
      sort: sortBy,
      order: sortDesc,
      page,
      limit: itemsPerPage,
      filter: search.query,
      fields: search.fields,
    }
  } else {
    // Pagination with no filters
    query = {
      sort: sortBy,
      order: sortDesc,
      page,
      limit: itemsPerPage,
    }
  }
  return query
}

// Catches error connection or any other error (checks if error.response exists)
export const handleError = (error, commit, reject) => {
  let errMsg = ""
  // Resets errors in store
  //commit(types.SHOW_LOADING, false, { root: true })
  //commit(types.ERROR, null, { root: true })

  // Checks if unauthorized
  if (!error.response) {
    commit(
      "addToastMessageObject",
      {
        message: error.message,
        color: "error",
        timeout: -1,
      },
      { root: true }
    )
  } else if (error.response.status === 401) {
    store.dispatch("userLogout")
  } else {
    // Any other error
    errMsg = error.response.data?.error
      ? error.response.data.error
      : error.message
    commit(types.ERROR, errMsg, { root: true })
    commit(
      "addToastMessageObject",
      {
        message: errMsg,
        color: "error",
        timeout: -1,
      },
      { root: true }
    )
  }
  if (reject) {
    reject(error)
  }
}

export const buildSuccess = (
  msg,
  commit,
  resolve,
  resolveParam = undefined
) => {
  commit(types.SHOW_LOADING, false, { root: true })
  commit(types.SUCCESS, msg, { root: true })
  commit(
    "addToastMessageObject",
    {
      message: msg.msg,
      color: "success",
      timeout: 3000,
    },
    { root: true }
  )
  if (resolve) {
    resolve(resolveParam)
  }
}

// Checks if tokenExpiration in localstorage date is past, if so then trigger an update
export const checkIfTokenNeedsRefresh = () => {
  // Checks if time set in localstorage is past to check for refresh token
  if (
    window.localStorage.getItem("token") !== null &&
    window.localStorage.getItem("tokenExpiration") !== null
  ) {
    if (
      isPast(
        new Date(
          parseISO(JSON.parse(window.localStorage.getItem("tokenExpiration"))) *
            1000
        )
      )
    ) {
      store.dispatch("refreshToken")
    }
  }
}
export const objectToQuery = object =>
  Object.keys(object)
    .map(key => {
      if (!Array.isArray(object[key])) {
        return `${key}=${object[key]}`
      }
      return object[key].map(el => `${key}[]=${el}`).join("&")
    })
    .join("&")

export const applyObjectValues = (toAssign, fromAssign) => {
  for (const key in fromAssign) {
    if (Object.hasOwnProperty.call(fromAssign, key)) {
      toAssign[key] = fromAssign[key]
    }
  }
}

export const generatePassword = () => {
  const length = 12
  const charset =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let password = ""

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length)

    password += charset.charAt(randomIndex)
  }

  if (!validatePassword(password)) {
    return generatePassword()
  }

  return password
}

export const downloadFile = async (url, filename) => {
  return axios
    .get(url, {
      responseType: "blob",
    })
    .then(res => {
      fileDownload(res.data, filename)
    })
    .catch(() => {
      throw new Error("Download error")
    })
}

export const converPriceToList = price => {
  let num = parseFloat(price.replace(",", "."))

  if (!isNaN(num)) {
    return num
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$& ")
      .replace(".", ",")
  }

  return price
}
