import axios from "axios"

// import { DummyApiService } from "@/services";
import UserService from "@/services/api/v1/UserService"
const service = new UserService()
export const permissions = {
  CAN_ADD_IMPORTER_USER: "CAN_ADD_IMPORTER_USER", // Добавление пользователей импортера
  CAN_ADD_DEALER: "CAN_ADD_DEALER", // Добавления дилеров
  CAN_ADD_DEALER_USER: "CAN_ADD_DEALER_USER", // Добавление пользователей дилера
  CAN_ADD_NSTO: "CAN_ADD_NSTO", //Добавление НСТО
  CAN_ADD_NSTO_USER: "CAN_ADD_NSTO_USER", // Добавление пользователей НСТО
  CAN_WORKS_WITH_CONTRACTORS: "CAN_WORKS_WITH_CONTRACTORS", // Работа с контрагентами
  CAN_SEARCH_SPARE_PART: "CAN_SEARCH_SPARE_PART", // Поиск ЗЧ
  CAN_ORDER_SPARE_PART: "CAN_ORDER_SPARE_PART", // Заказ ЗЧ
  CAN_WORK_WITH_PRICES: "CAN_WORK_WITH_PRICES", // Прайсы
  CAN_WORK_WITH_PROMOTIONS: "CAN_WORK_WITH_PROMOTIONS", // Акции
  CAN_WORK_WITH_REPORTS: "CAN_WORK_WITH_REPORTS", // Отчеты
  CAN_WORK_WITH_LOYALTY: "CAN_WORK_WITH_LOYALTY", // Лояльность
  CAN_WORK_WITH_CREDIT_LIMIT: "CAN_WORK_WITH_CREDIT_LIMIT", // кредитный лимит
}
export const roles = {
  ADMIN: "ADMIN", // Администратор Портала
  IMPORTER_ADMIN: "IMPORTER_ADMIN", // Администратор импортера
  IMPORTER_OPERATOR: "IMPORTER_OPERATOR", // Оператор импортера
  IMPORTER_ANALYST: "IMPORTER_ANALYST", // Аналитик импортера
  IMPORTER_ORDER_OPERATOR: "IMPORTER_ORDER_OPERATOR", // Оператор заказов
  DEALER_ADMIN: "DEALER_ADMIN", // Администратор дилера
  DEALER_OPERATOR: "DEALER_OPERATOR", // Оператор дилера
  DEALER_ANALYST: "DEALER_ANALYST", // Аналитик дилера
  DEALER_ORDER_OPERATOR: "DEALER_ORDER_OPERATOR", // Оператор заказов
  NSTO_ADMIN: "NSTO_ADMIN", // Администратор НСТО
  NSTO_OPERATOR: "NSTO_OPERATOR", // Оператор НСТО
  NSTO_ANALYST: "NSTO_ANALYST", // Аналитик НСТО
  NSTO_ORDER_OPERATOR: "NSTO_ORDER_OPERATOR", // Оператор заказов
  NSTO_OPERATOR_LOYALTY: "NSTO_OPERATOR_LOYALTY", // Оператор программы лояльности
}
export const clientRoles = [
  roles.NSTO_ADMIN,
  roles.NSTO_ANALYST,
  roles.NSTO_OPERATOR,
  roles.NSTO_OPERATOR_LOYALTY,
  roles.NSTO_ORDER_OPERATOR,
]
export const dealerRoles = [
  roles.DEALER_ADMIN,
  roles.DEALER_OPERATOR,
  roles.DEALER_ORDER_OPERATOR,
  roles.DEALER_ANALYST,
]
export const importerRoles = [
  roles.IMPORTER_ADMIN,
  roles.IMPORTER_ANALYST,
  roles.IMPORTER_ORDER_OPERATOR,
  roles.IMPORTER_OPERATOR,
]
export const state = {
  authError: null,
  resetError: null,
  token: localStorage.getItem("token") ?? null,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  rolesList: {
    ADMIN: "Администратор Портала",
    IMPORTER_ADMIN: "Администратор импортера",
    IMPORTER_OPERATOR: "Оператор импортера",
    IMPORTER_ANALYST: "Аналитик импортера",
    IMPORTER_ORDER_OPERATOR: "Оператор заказов",
    DEALER_ADMIN: "Администратор дилера",
    DEALER_OPERATOR: "Оператор дилера",
    DEALER_ANALYST: "Аналитик дилера",
    DEALER_ORDER_OPERATOR: "Оператор заказов",
    NSTO_ADMIN: "Администратор НСТО",
    NSTO_OPERATOR: "Оператор НСТО",
    NSTO_ANALYST: "Аналитик НСТО",
    NSTO_ORDER_OPERATOR: "Оператор заказов",
    NSTO_OPERATOR_LOYALTY: "Оператор программы лояльности",
  },
  rolesNames: {
    ADMIN: "ADMIN",
    IMPORTER_ADMIN: "IMPORTER_ADMIN",
    IMPORTER_OPERATOR: "IMPORTER_OPERATOR",
    IMPORTER_ANALYST: "IMPORTER_ANALYST",
    IMPORTER_ORDER_OPERATOR: "IMPORTER_ORDER_OPERATOR",
    DEALER_ADMIN: "DEALER_ADMIN",
    DEALER_OPERATOR: "DEALER_OPERATOR",
    DEALER_ANALYST: "DEALER_ANALYST",
    DEALER_ORDER_OPERATOR: "DEALER_ORDER_OPERATOR",
    NSTO_ADMIN: "NSTO_ADMIN",
    NSTO_OPERATOR: "NSTO_OPERATOR",
    NSTO_ANALYST: "NSTO_ANALYST",
    NSTO_ORDER_OPERATOR: "NSTO_ORDER_OPERATOR",
    NSTO_OPERATOR_LOYALTY: "NSTO_OPERATOR_LOYALTY",
  },
  // Домашняя страница роли
  rolesHomeRoute: {
    ADMIN: "/",
    IMPORTER_ADMIN: "/dealers",
    IMPORTER_OPERATOR: "/promotions",
    IMPORTER_ANALYST: "/reports",
    IMPORTER_ORDER_OPERATOR: "/dealers",
    DEALER_ADMIN: "/orders",
    DEALER_OPERATOR: "/promotions",
    DEALER_ANALYST: "/reports",
    DEALER_ORDER_OPERATOR: "/orders",
    NSTO_ADMIN: "/",
    NSTO_OPERATOR: "/promotions",
    NSTO_ANALYST: "/reports",
    NSTO_ORDER_OPERATOR: "/promotions",
    NSTO_OPERATOR_LOYALTY: "/dealers",
  },
}

export const mutations = {
  SET_AUTH_TOKEN(state, payload) {
    state.token = payload
  },
  SET_USER_INFO(state, payload) {
    state.user = payload
  },
  CLEAR_USER_INFO(state) {
    state.user = null
  },
  SET_AUTH_ERROR(state, payload) {
    state.authError = payload
  },
  SET_RESET_ERROR(state, payload) {
    state.resetError = payload
  },
  CLEAR_AUTH_ERROR(state) {
    state.authError = null
  },
}

export const actions = {
  login({ commit, dispatch, getters }, payload) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_AUTH_ERROR")

      service
        .auth(payload.login, payload.password)
        .then(response => {
          const token = response.data.access_token
          commit("SET_AUTH_TOKEN", token)
          localStorage.setItem("token", token)
          axios.defaults.headers.common["Authorization"] = getters.bearerToken
          dispatch("getUser").then(() => resolve(token))
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
            commit("SET_AUTH_ERROR", error.response.data.message)
          }
          reject(error.response)
        })
    })
  },
  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      service
        .getCurrentUser()
        .then(response => {
          const user = response.data.data
          commit("SET_USER_INFO", user)
          localStorage.setItem("user", JSON.stringify(user))
          resolve(user)
        })
        .catch(error => {
          console.log(error)
          if (error.response) {
            commit("SET_AUTH_ERROR", error.response.data.message)
          }
          reject(error.response)
        })
    })
  },
  logout({ commit }) {
    commit("CLEAR_USER_INFO")
    axios.defaults.headers.common["Authorization"] = null
    localStorage.removeItem("user")
    localStorage.removeItem("token")
  },
  async createUser(_, userInfo) {
    return service.setNewUser(userInfo)
  },
  async createDealerUser(_, userInfo) {
    console.log("createDealerUser", userInfo)
    return service.setNewDealerUser(userInfo)
  },
  async resetPassword({ commit }, email) {
    return new Promise((resolve, reject) => {
      service
        .resetPassword(email)
        .then(() => {
          resolve()
        })
        .catch(error => {
          if (error.response) {
            commit("SET_AUTH_ERROR", error.response.data.message)
          }
          reject(error.response)
        })
    })
  },
  async resetPasswordConfirm(_, resetData) {
    return new Promise((resolve, reject) => {
      service
        .resetPasswordConfirm(resetData)
        .then(() => {
          resolve()
        })
        .catch(error => {
          reject(error.response)
        })
    })
  },
}

export const getters = {
  authError: state => state.authError,
  resetError: state => state.resetError,
  isLoggedIn: state => state.token,
  rolesNames: state => state.rolesNames,
  bearerToken: state => (state.token ? `Bearer ${state.token}` : ""),
  user: state => state.user,
  userRole: state => (state.user && state.token ? state.user.roles[0] : null),
  userDealerId: state =>
    state.user && state.token ? state.user.roles[0] : null,
  roles: state => (state.user && state.token ? state.user.roles : []),
  rolesNamesList: state =>
    state.user && state.token
      ? state.user.roles.map(role => state.rolesNames[role])
      : [],
  isBuyer: state =>
    state.user?.roles
      ? state.user?.roles?.some(el => clientRoles.includes(el))
      : false,
  isDealer: state =>
    state.user?.roles
      ? state.user?.roles?.some(el => dealerRoles.includes(el))
      : false,
  isOwner: state =>
    state.user?.roles
      ? state.user?.roles?.some(el => importerRoles.includes(el))
      : false,
  isAdmin: state =>
    state.user?.roles ? state.user?.roles?.includes(roles.ADMIN) : false,
  role: state =>
    state.user?.roles ? state.rolesList[state.user.roles[0]] : null,
  rolesHomeRoute: state => state.rolesHomeRoute,
}

export function hasPermisson(permission) {
  return (
    state.user &&
    state.user.permissons &&
    state.user.permissons.find(permission)
  )
}
export function hasRole(role) {
  return state.user && state.user.roles && state.user.roles.includes(role)
}
