export default [
  {
    key: "1",
    code: "N201-024",
    name: "Серена",
    ulName: `ООО "Серена"`,
    inn: "6950072413",
    optGroup: "Опт2",
    priceListPresence: "3%",
    priceListImmediate: "5%",
    priceListWarehouse: "5%",
    priceListNotLiquid: "-10%",
    mail: "zakaz@seren1.ru",
  },
  {
    key: "2",
    code: "N201-003",
    name: "Апбуров ИП",
    ulName: `ИП Апбуров И.А.`,
    inn: "23104188203",
    optGroup: "Опт2",
    priceListPresence: "5%",
    priceListImmediate: "7%",
    priceListWarehouse: "5%",
    priceListNotLiquid: "0%",
    mail: "big_jeep@km.ru",
  },
  {
    key: "3",
    code: "N201-017",
    name: "Ростехнадзор",
    ulName: `ФГУП "Ростехнадзор"`,
    inn: "4205206546",
    optGroup: "Опт1",
    priceListPresence: "3%",
    priceListImmediate: "3%",
    priceListWarehouse: "3%",
    priceListNotLiquid: "-10%",
    mail: "ivan172@yandex.ru",
  },
]
