<template>
  <div class="footer">
    <p>
      Geely
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.footer {
  text-align: center;
  font-family: $fontf-main;
  font-weight: 300;
  font-size: small;
}
</style>
