export default [
  {
    key: 1,
    dealerName: "АВТОГРАД ПРЕСТИЖ",
    approvedLinesAmount: "62",
    approvedLinesPresence: "31",
    approvedLinesSroch: "22",
    approvedLinesSklad: "8",
    approvedLinesOrder: "0",
    approvedLinesOtherDealers: "1",
    approvedLinesOther: "0",
  },
  {
    key: 2,
    dealerName: "БОВИД АВТО",
    approvedLinesAmount: "103",
    approvedLinesPresence: "47",
    approvedLinesSroch: "10",
    approvedLinesSklad: "31",
    approvedLinesOrder: "4",
    approvedLinesOtherDealers: "6",
    approvedLinesOther: "5",
  },
  {
    key: 3,
    dealerName: "АВТОГРАД ПЛЮС",
    approvedLinesAmount: "275",
    approvedLinesPresence: "192",
    approvedLinesSroch: "32",
    approvedLinesSklad: "36",
    approvedLinesOrder: "8",
    approvedLinesOtherDealers: "0",
    approvedLinesOther: "7",
  },
  {
    key: 4,
    dealerName: "ПЛАНЕТА АВТО",
    approvedLinesAmount: "29",
    approvedLinesPresence: "12",
    approvedLinesSroch: "9",
    approvedLinesSklad: "8",
    approvedLinesOrder: "0",
    approvedLinesOtherDealers: "0",
    approvedLinesOther: "0",
  },
  {
    key: 5,
    dealerName: "АРКОНТ",
    approvedLinesAmount: "74",
    approvedLinesPresence: "36",
    approvedLinesSroch: "24",
    approvedLinesSklad: "1",
    approvedLinesOrder: "3",
    approvedLinesOtherDealers: "1",
    approvedLinesOther: "9",
  },
]
