import axios from "axios"
import store from "@/store/store"
import router from "@/router/index"

axios.interceptors.response.use(
  response => response,
  error => {
    if (401 === error.response.status) {
      store.dispatch("auth/logout").then(() => router.push("/login"))
    }

    return Promise.reject(error)
  }
)
