import axios from "axios"

import errorHandler from "@/utils/errorHandler"
import { API_PREFIX } from "@/services/api/v1/const"

export async function getPaymentGroups() {
  try {
    return await axios.get(`${API_PREFIX}/payment-groups`)
  } catch (error) {
    errorHandler(error)
  }
}
export async function setPaymentGroups(ar) {
  return await axios.post(`${API_PREFIX}/payment-groups-add-all`, {
    groups: ar,
  })
}
