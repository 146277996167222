import OrderService from "@/services/api/v1/OrderService"
const service = new OrderService()

const orderClientEmpty = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  comment: "",
}

const orderDeliveryEmpty = {
  type: 2,
  postcode: "",
  country: "Россия",
  region: "",
  city: "",
  street: "",
  building: "",
  apartment: "",
  address_string: "",
}

const orderPaymentEmpty = {
  type: "cash",
}

export const state = {
  orders: [],
  corrections: {},
  paginationOptions: {
    pageSize: 10,
    totalCount: 0,
    currentPage: 1,
  },
  order: {
    recipient: {
      ...orderClientEmpty,
    },
    delivery: {
      ...orderDeliveryEmpty,
    },
    payment: {
      ...orderPaymentEmpty,
    },
  },
  deliveryTypes: [
    {
      name: "Доставка",
      key: "delivery",
    },
    {
      name: "Самовывоз",
      key: "shop",
    },
  ],
  paymentTypes: [
    {
      name: "Наличными курьеру",
      key: "cash",
    },
    {
      name: "Оплата по карте",
      key: "card",
    },
    {
      name: "Оплата по счету",
      key: "bill",
    },
  ],
  columns: [
    {
      title: "№",
      dataIndex: "number",
      key: "number",
      width: 70,
      sorter: (a, b) => a.number - b.number,
    },
    {
      title: "Артикул",
      dataIndex: "code",
      key: "code",
      width: 150,

      sorter: (a, b) => a.code - b.code,
    },
    {
      title: "Наименование",
      dataIndex: "catalog_product",
      key: "name",
      width: 200,
      scopedSlots: { customRender: "name" },
      sorter: (a, b) => a.name.length - b.name.length,
    },
    // {
    //   title: "Срок доставки",
    //   dataIndex: "period",
    //   key: "period",
    //   align: "center",
    //   width: 100,
    //   scopedSlots: { customRender: "period" },
    //   sorter: (a, b) => a.period - b.period,
    // },
    {
      title: "Количество",
      dataIndex: "count",
      key: "count",
      align: "center",
      width: 120,
      scopedSlots: { customRender: "count" },
      sorter: (a, b) => a.initValue - b.initValue,
    },
    {
      title: "Цена",
      dataIndex: "price",
      key: "price",
      width: 100,
      scopedSlots: { customRender: "price" },
      sorter: (a, b) => a.calculate_price - b.calculate_price,
    },
  ],
}

export const mutations = {
  SET_LIST(state, payload) {
    state.orders = payload
  },
  SET_CORRECTIONS(state, payload) {
    state.corrections = payload
  },
  CLEAR_LIST(state) {
    state.orders = []
    state.paginationOptions.currentPage = 1
  },
  SET_CURRENT_PAGE(state, payload) {
    state.paginationOptions.currentPage = payload
  },
  SET_TOTAL_COUNT(state, payload) {
    state.paginationOptions.totalCount = payload
  },
  SET_ORDER_CLIENT_FIELD(state, { key, value }) {
    state.order.recipient[key] = value
  },
  SET_ORDER_DELIVERY_FIELD(state, { key, value }) {
    console.log(key, value)
    let delivery = { ...state.order.delivery }
    delivery[key] = value
    state.order = { ...state.order, delivery: delivery }
  },
  SET_ORDER_PAYMENT_FIELD(state, { key, value }) {
    state.order.payment[key] = value
  },
  CLEAR_ORDER(state) {
    state.order.recipient = {
      ...orderClientEmpty,
    }
    state.order.delivery = {
      ...orderDeliveryEmpty,
    }
    state.order.payment = {
      ...orderPaymentEmpty,
    }
  },
}

export const actions = {
  async getOrders({ commit, getters }, savePage = false) {
    const paginationOptions = getters.paginationOptions

    let currentPage = paginationOptions.currentPage
    if (!savePage) {
      commit("SET_CURRENT_PAGE", 1)
      currentPage = 1
    }

    const response = await service.getOrders(
      paginationOptions.pageSize,
      (currentPage - 1) * paginationOptions.pageSize
    )

    commit("SET_TOTAL_COUNT", response.data.data.length)
    commit("SET_LIST", response.data.data)
  },
  async getOrder(_, uuid) {
    const response = await service.getOrder(uuid)
    return response.data.order
  },
  async getRefundStatuses() {
    return await service.getRefundStatuses()
  },
  async getOrderStatuses() {
    return await service.getOrderStatuses()
  },
  async createOrder({ getters, rootGetters }, selected) {
    return await service.createOrder({
      ...getters.order,
      basketUuid: rootGetters["basket/basket"].uuid,
      selected: selected,
    })
  },
  async updateOrder(_, { id, number }) {
    return await service.updateOrder(id, number)
  },
  async getCorrections({ commit }, { id }) {
    const obj = {}
    const { data } = await service.getCorrections(id)

    data.data.forEach(el => {
      obj[el.number] = el
    })

    commit("SET_CORRECTIONS", obj)
  },
  async getCorrectionProducts() {
    return await service.getCorrectionProducts()
  },
  async deleteCorrectionProducts(_, { id }) {
    return await service.deleteCorrectionProducts(id)
  },
  async createCorrection(_, { id, data }) {
    return await service.createCorrection(id, data)
  },
  async changeStatusCorrection(_, { id, data }) {
    return await service.changeStatusCorrection(id, data)
  },
  async submitCorrection(_, { id, data }) {
    return await service.submitCorrection(id, data)
  },
  async setStatus(_, { order, status, comment }) {
    return await service.setstatus(order, status, comment)
  },
  async refundProduct(_, { orderId, productId, count }) {
    return await service.refundProduct(orderId, productId, count)
  },
  async sendNotificationn(_, { id }) {
    return await service.sendNotification(id)
  },
}

export const getters = {
  orders: state => state.orders,
  columns: state => state.columns,
  corrections: state => state.corrections,
  paginationOptions: state => state.paginationOptions,
  order: state => state.order,
  orderDelivery: state => state.order.delivery,
  orderClient: state => state.order.recipient,
  orderPayment: state => state.order.payment,
  deliveryTypes: state => state.deliveryTypes,
  paymentTypes: state => state.paymentTypes,
}
