/**
 * IMPORTER_ADMIN
 */

export default (() => {
  return {
    IMPORTER_ADMIN: {
      defaultSelectedId: ["Dealers"],
      items: [
        {
          id: "Dealers",
          icon: "exclamation-circle",
          name: "Дилеры",
          path: "/dealers",
        },
        // {
        //   id: "Promotions",
        //   name: "Акции",
        //   icon: "percentage",
        //   path: "/promotions",
        // },
        {
          id: "Reports",
          name: "Отчеты",
          icon: "bar-chart",
          path: "/reports",
        },
        {
          id: "Price",
          name: "Прайс-листы",
          icon: "container",
          path: "/prices",
        },
        {
          id: "Clients",
          name: "Клиенты",
          icon: "team",
          path: "/clients",
        },
      ],
    },
  }
})()
