export default [
  {
    key: 1,
    linesData: "Количество заказанных линий",
    byAllOrders: "317",
    presenceStore: "213",
    srochCS: "36",
    skladCS: "48",
    orderCS: "9",
    otherDealers: "2",
    other: "9",
  },
  {
    key: 2,
    linesData: "Количество отказанных линий",
    byAllOrders: "26",
    presenceStore: "11",
    srochCS: "1",
    skladCS: "12",
    orderCS: "0",
    otherDealers: "0",
    other: "2",
  },
  {
    key: 3,
    linesData: "Количество подтвержденных линий",
    byAllOrders: "275",
    presenceStore: "192",
    srochCS: "32",
    skladCS: "36",
    orderCS: "8",
    otherDealers: "0",
    other: "7",
  },
  {
    key: 4,
    linesData: "Количество линий без статуса",
    byAllOrders: "16",
    presenceStore: "10",
    srochCS: "3",
    skladCS: "0",
    orderCS: "1",
    otherDealers: "2",
    other: "0",
  },
  {
    key: 5,
    linesData: "Количество отгруженных линий",
    byAllOrders: "213",
    presenceStore: "81",
    srochCS: "52",
    skladCS: "29",
    orderCS: "5",
    otherDealers: "0",
    other: "46",
  },
  {
    key: 6,
    linesData: "Коэффициент Отказов",
    byAllOrders: "0,08",
    presenceStore: "0,05",
    srochCS: "0,03",
    skladCS: "0,25",
    orderCS: "0,00",
    otherDealers: "0,00",
    other: "0,22",
  },
  {
    key: 7,
    linesData: "Коэффициент Подтверждения",
    byAllOrders: "0,87",
    presenceStore: "0,90",
    srochCS: "0,89",
    skladCS: "0,75",
    orderCS: "0,89",
    otherDealers: "0,00",
    other: "0,78",
  },
]
