import Vue from 'vue'
import { v4 as uuidv4 } from 'uuid'
/**
 * Create default mutations and merge them with mutations defined by a user.
 */
const createMutations = ({
  mutations,
  only,
  idAttribute,
  onFetchListStart,
  onFetchListSuccess,
  onFetchListError,
  onFetchSingleStart,
  onFetchSingleSuccess,
  onFetchSingleError,
  onCreateStart,
  onCreateSuccess,
  onCreateError,
  onUpdateStart,
  onUpdateSuccess,
  onUpdateError,
  onReplaceStart,
  onReplaceSuccess,
  onReplaceError,
  onDestroyStart,
  onDestroySuccess,
  onDestroyError
}) => {
  const crudMutations = {}

  if (only.includes('FETCH_LIST')) {
    Object.assign(crudMutations, {
      fetchListStart(state) {
        state.isFetchingList = true
        onFetchListStart(state)
      },

      fetchListSuccess(state, response) {
        const { data } = response

        data.forEach((m) => {
          Vue.set(state.entities, m[idAttribute].toString(), m)
        })
        state.list = data.map((m) => m[idAttribute].toString())
        state.isFetchingList = false
        state.fetchListError = null
        onFetchListSuccess(state, response)
      },

      fetchFilesSuccess(state, response) {
        const { data } = response

        state.files = data
        state.isFetchingList = false
        state.fetchListError = null
        onFetchListSuccess(state, response)
      },

      fetchListError(state, err) {
        state.list = []
        state.fetchListError = err
        state.isFetchingList = false
        onFetchListError(state, err)
      }
    })
  }

  if (only.includes('FETCH_SINGLE')) {
    Object.assign(crudMutations, {
      fetchSingleStart(state) {
        state.isFetchingSingle = true
        onFetchSingleStart(state)
      },

      fetchSingleSuccess(state, response) {
        const { data } = response
        const id = data[idAttribute].toString()

        Vue.set(state.entities, id, data)
        state.isFetchingSingle = false
        state.fetchSingleError = null
        onFetchSingleSuccess(state, response)
      },

      fetchSingleError(state, err) {
        state.fetchSingleError = err
        state.isFetchingSingle = false
        onFetchSingleError(state, err)
      }
    })
  }

  if (only.includes('CREATE')) {
    Object.assign(crudMutations, {
      createStart(state) {
        state.isCreating = true
        onCreateStart(state)
      },

      createSuccess(state, response) {
        const { data } = response
        if (data) {
          const id = data[idAttribute].toString()
          Vue.set(state.entities, id, data)
          state.list.push(id)
        }
        state.isCreating = false
        state.createError = null
        onCreateSuccess(state, response)
      },

      createLocal(state, data) {
        if (data) {
          const id = uuidv4()
          data[idAttribute] = id
          Vue.set(state.entities, id, data)
          state.list.push(id)
        }
      },

      createError(state, err) {
        state.createError = err
        state.isCreating = false
        onCreateError(state, err)
      }
    })
  }

  if (only.includes('UPDATE')) {
    Object.assign(crudMutations, {
      updateLocal(state, data) {
        const id = data[idAttribute].toString()
        Vue.set(state.entities, id, data)
      },

      updateStart(state) {
        state.isUpdating = true
        onUpdateStart(state)
      },

      updateSuccess(state, response) {
        const { data } = response

        const id = data[idAttribute].toString()

        Vue.set(state.entities, id, data)

        const listIndex = state.list.indexOf(id)

        if (listIndex >= 0) {
          Vue.set(state.list, listIndex, id)
        }

        state.isUpdating = false
        state.updateError = null
        onUpdateSuccess(state, response)
      },

      updateError(state, err) {
        state.updateError = err
        state.isUpdating = false
        onUpdateError(state, err)
      }
    })
  }

  if (only.includes('REPLACE')) {
    Object.assign(crudMutations, {
      replaceStart(state) {
        state.isReplacing = true
        onReplaceStart(state)
      },

      replaceSuccess(state, response) {
        const { data } = response

        const id = data[idAttribute].toString()

        Vue.set(state.entities, id, data)

        const listIndex = state.list.indexOf(id)

        if (listIndex >= 0) {
          Vue.set(state.list, listIndex, id)
        }

        state.isReplacing = false
        state.replaceError = null
        onReplaceSuccess(state, response)
      },

      replaceError(state, err) {
        state.replaceError = err
        state.isReplacing = false
        onReplaceError(state, err)
      }
    })
  }

  if (only.includes('DESTROY')) {
    Object.assign(crudMutations, {
      destroyLocalById(state, id) {
        const listIndex = state.list.indexOf(id.toString())

        if (listIndex >= 0) {
          Vue.delete(state.list, listIndex)
        }

        Vue.delete(state.entities, id.toString())
      },
      destroyLocal(state, data) {
        const id = data[idAttribute]
        const listIndex = state.list.indexOf(id.toString())

        if (listIndex >= 0) {
          Vue.delete(state.list, listIndex)
        }

        Vue.delete(state.entities, id.toString())
      },
      destroyStart(state) {
        state.isDestroying = true
        onDestroyStart(state)
      },

      destroySuccess(state, id, response) {
        const listIndex = state.list.indexOf(id.toString())

        if (listIndex >= 0) {
          Vue.delete(state.list, listIndex)
        }

        Vue.delete(state.entities, id.toString())

        state.isDestroying = false
        state.destroyError = null
        onDestroySuccess(state, response)
      },

      destroyError(state, err) {
        state.destroyError = err
        state.isDestroying = false
        onDestroyError(state, err)
      }
    })
  }

  Object.assign(crudMutations, {
    setList(state, data) {
      state.entities = {}
      data.forEach((m) => {
        if (!m[idAttribute]) {
          m[idAttribute] = uuidv4()
        }
        Vue.set(state.entities, m[idAttribute].toString(), m)
      })
      state.list = data.map((m) => m[idAttribute].toString())
    },
    setCurrent(state, item) {
      state.current = item
    },
    setCurrentById(state, id) {
      state.current = state.entities[id]
    },
    setAllCount(state, count) {
      state.allCount = count
    },
    setPagination(state, data) {
      state.pagination = { ...data }
    },
    clearFilter(state) {
      state.searchFilter = {}
    },
    setFilterKeyValue(state, data) {
      state.searchFilter = { ...state.searchFilter, ...data }
    },
    setPaginationKeyValue(state, data) {
      state.pagination = { ...state.pagination, ...data }
    }
  })

  return Object.assign(crudMutations, mutations)
}

export default createMutations
