import axios from "axios"
import { API_PREFIX } from "@/services/api/v1/const"

export default class ProductService {
  async auth(login, password) {
    return axios.post(`${API_PREFIX}/login`, {
      login,
      password,
    })
  }

  async search(limit, page, filters) {
    const formattedFilters = {}

    for (const key in filters) {
      if (key === "search") {
        formattedFilters[key] = filters[key]
      } else {
        formattedFilters[`filter[${key}]`] = filters[key]
      }
    }

    const options = {
      ...formattedFilters,
      per_page: limit,
      page,
    }

    return axios.get(
      `${API_PREFIX}/product?XDEBUG_SESSION=PHPSTORM&${new URLSearchParams(
        options
      ).toString()}`
    )
  }
}
