import ClientService from "@/services/api/v1/ClientService"
import CreateClientService from "@/services/api/v1/CreateClientService"

const service = new ClientService()

const createService = new CreateClientService()

export const state = {
  columns: [
    {
      title: "ИД",
      dataIndex: "id",
      key: "id",
      width: 100,
    },
    {
      title: "ЮЛ",
      dataIndex: "legal_info.name",
      width: 180,
    },
    {
      title: "ИНН",
      dataIndex: "legal_info.inn",

      width: 110,
    },
    {
      title: "Дилер",
      dataIndex: "dealers",
      scopedSlots: { customRender: "dealers" },
      width: 110,
    },
    {
      title: "Группа опт",
      dataIndex: "payment_group.name",
      key: "payment_group.name",
      width: 110,
    },
    {
      title: "Юридический адрес",
      dataIndex: "legal_info.address",
      key: 'legal_info.address"',
      width: 130,
    },
    // {
    //   title: "Прайс-лист наличие",
    //   dataIndex: "priceListPresence",
    //   key: "priceListPresence",
    //   width: 110,
    // },
    // {
    //   title: "Прайс-лист ЦС срочный",
    //   dataIndex: "priceListImmediate",
    //   key: "priceListImmediate",
    //   width: 130,
    // },
    // {
    //   title: "Прайс-лист ЦС складской",
    //   dataIndex: "priceListWarehouse",
    //   key: "priceListWarehouse",
    //   width: 130,
    // },
    // {
    //   title: "Прайс-лист Неликвид",
    //   dataIndex: "priceListNotLiquid",
    //   key: "priceListNotLiquid",
    //   width: 110,
    // },
    // {
    //   title: `Эл. адрес "Заказы"`,
    //   dataIndex: "mail",
    //   key: "mail",
    //   width: 150,
    // },
    {
      title: "Действия",
      dataIndex: "operation",
      scopedSlots: { customRender: "operation" },
      className: "column-action",
      width: 110,
    },
  ],
  columnsClientUsers: [
    {
      title: "Имя пользователя",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Роль",
      dataIndex: "roles",
      key: "roles",
    },
    {
      title: "Действия",
      dataIndex: "operation",
      scopedSlots: { customRender: "operation" },
      className: "column-action",
      width: 110,
    },
  ],
  clients: [],
  clientUsers: [],
  paymentGroup: null,
  filters: {
    name: null,
    paymentGroup: null,
    inn: null,
  },
  // set: = value
  // clear: = null
  createFormError: null,
  contactNames: [
    "Взаиморасчеты",
    "Отправка прайс-листов",
    "Руководитель",
    "Заказы",
    "Возвраты",
    "Обновление паролей",
  ],
  // name: null
  // headName
  createForm: {
    name: "",
    paymentGroup: {
      id: "",
      name: "",
      ordering: "",
    },
    dealer_id: "",
    userId: "",
    user: "",
    headName: "",
    headPosition: "",
    legalInfo: {
      name: "",
      paymentAccount: "",
      bankId: "",
      bankName: "",
      correspondedAccount: "",
      underlyingDocument: "",
      inn: "",
      kpp: "",
      ogrn: "",
      okpo: "",
      address: "",
      physicalAddress: "",
    },
    contacts: [
      {
        type: "Взаиморасчеты",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Отправка прайс-листов",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Руководитель",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Заказы",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Возвраты",
        name: "",
        phone: "",
        email: "",
      },
      {
        type: "Обновление паролей",
        name: "",
        phone: "",
        email: "",
      },
    ],
  },
}
export const mutations = {
  SET_CLIENTS(state, payload) {
    state.clients = payload
  },
  CLEAR_FILTERS(state) {
    state.filters.paymentGroup = null
    state.filters.name = null
    state.filters.inn = null
  },
  SET_FILTER_NAME(state, payload) {
    state.filters.name = payload
  },
  SET_FILTER_INN(state, payload) {
    state.filters.inn = payload
  },
  SET_FILTER_DEALER(state, payload) {
    state.filters.dealer = payload
  },
  SET_FILTER_PAYMENT_GROUP(state, payload) {
    state.filters.paymentGroup = payload
  },
  SET_CREATE_CLIENT_FORM_ERROR(state, payload) {
    state.createFormError = payload
  },
  CLEAR_CREATE_CLIENT_FORM_ERROR(state) {
    state.createFormError = null
  },
  SET_CREATE_FORM_CONTACT_FIELD(state, payload) {
    state.createForm.contacts[payload.index][payload.field] = payload.value
  },
  SET_CREATE_FORM_FIELD(state, payload) {
    const o = { ...state.createForm }
    o[payload.name] = payload.value
    state.createForm = o
  },
  SET_CREATE_FORM(state, payload) {
    state.createForm = payload
  },
  SET_PAYMENT_GROUPS(state, payload) {
    state.paymentGroup = payload
  },
  DEL_CLIENT_ITEM(state, payload) {
    state.clients = state.clients.filter(el => el.id !== payload)
  },
  SET_CLIENT_USERS(state, payload) {
    state.clientUsers = payload
  },
  DEL_CLIENT_USER_ITEM(state, payload) {
    state.clientUsers = state.clientUsers.filter(el => el.id !== payload)
  },
}

export const actions = {
  // eslint-disable-next-line no-unused-vars
  async setFinanceAccountAmount({ commit, getters }, data) {
    const clientId = getters.createForm.id
    return new Promise((resolve, reject) => {
      createService
        .setFinanceAccountAmount({
          clientId: clientId,
          amount: data.amount,
          days: data.days,
          payment_type: data.payment_type,
        })
        .then(resp => resolve(resp))
        .catch(error => {
          reject(error.response)
        })
    })
  },

  async fetchClients({ commit, getters }) {
    const filters = getters.filters
    const response = await service.search(
      filters.paymentGroup,
      filters.name,
      filters.inn,
      filters.dealer
    )

    commit("SET_CLIENTS", response.data.data)
  },
  async searchClients({ commit, getters }) {
    const filters = getters.filters
    const response = await service.searchglobal(
      filters.paymentGroup,
      filters.name,
      filters.inn,
      filters.dealer
    )

    commit("SET_CLIENTS", response.data.items)
  },
  async createClient({ commit, getters }, userResp) {
    const clientFormData = getters.createForm
    // clientFormData.contacts = clientFormData.contacts.filter(item => {
    //   if (item && (item.name || item.phone || item.email)) {
    //     return item
    //   }
    // })
    let data = Object.assign(userResp, clientFormData)
    return new Promise((resolve, reject) => {
      commit("CLEAR_CREATE_CLIENT_FORM_ERROR")
      createService
        .createClient(data)
        .then(resp => resolve(resp))
        .catch(error => {
          console.log(error)
          reject(error.response)
        })
    })
  },
  async bindExistingClientToDealer(s, payload) {
    await createService
      .bindClient({ id: payload.clientId, dealer_id: payload.dealerId })
      .catch(function(error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          throw new Error(error.response.data.message)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log("Error", error.message)
        }
        console.log(error.config)
      })
  },
  async updateClient({ commit, getters }) {
    console.log(getters.createForm)
    const clientFormData = JSON.parse(JSON.stringify(getters.createForm))
    console.log(clientFormData)
    // clientFormData.contacts = clientFormData.contacts.filter(item => {
    //   if (item.name || item.phone || item.email) {
    //     return item
    //   }
    // })
    return new Promise((resolve, reject) => {
      commit("CLEAR_CREATE_CLIENT_FORM_ERROR")
      service
        .updateClient(clientFormData)
        .then(() => resolve())
        .catch(error => {
          console.log(error)
          // if (error.response) { commit("SET_CREATE_CLIENT_FORM_ERROR", error.response.data.message); }
          reject()
        })
    })
  },
  async deleteClient({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("CLEAR_CREATE_CLIENT_FORM_ERROR")
      commit("DEL_CLIENT_ITEM", id)
      service
        .delSingleClient(id)
        .then(() => resolve())
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  },
  async bindClientWithUser({ getters }, userId) {
    const { name, headName, headPosition } = getters.createForm
    const neededClient = await getters.clients.filter(el => {
      if (
        el.name === name &&
        el.headName === headName &&
        el.headPosition === headPosition
      ) {
        return el
      }
    })
    const [el] = neededClient
    return new Promise((resolve, reject) => {
      service
        .bindClient(userId, el.id)
        .then(() => resolve())
        .catch(e => {
          console.log(e)
          reject()
        })
    })
  },

  async getClient({ commit }, client) {
    const response = await service.getSingleClient(client.id)
    commit("SET_CREATE_FORM", {
      name: response.data.name,
      headName: response.data.headName,
      headPosition: response.data.headName,
      legalInfoName: response.data.legalInfo.name || "",
      legalInfoPaymentAccount: response.data.legalInfo.paymentAccount || "",
      legalInfoBankId: response.data.legalInfo.bankId || "",
      legalInfoBankName: response.data.legalInfo.bankName || "",
      legalInfoCorrespondedAccount:
        response.data.legalInfo.correspondedAccount || "",
      legalInfoUnderlyingDocument:
        response.data.legalInfo.underlyingDocument || "",
      legalInfoInn: response.data.legalInfo.inn || "",
      legalInfoKpp: response.data.legalInfo.kpp || "",
      legalInfoOgrn: response.data.legalInfo.ogrn || "",
      legalInfoOkpo: response.data.legalInfo.okpo || "",
      legalInfoAddress: response.data.legalInfo.address || "",
      legalInfoPhysicalAddress: response.data.legalInfo.physicalAddress || "",
      paymentGroup: response.data.paymentGroup,
      contacts: response.data.contacts,
      user: {},
      id: response.data.id,
      status: response.data.status,
      createdAt: response.data.createdAt,
      dealer_id: client.dealer_id,
      delivery_addresses: response.data.delivery_addresses.length
        ? response.data.delivery_addresses
        : [{ address: response.data.legalInfo.physicalAddress }],
      delivery_type: response.data.delivery_type,
      mass_restriction: response.data.mass_restriction,
      financeAccount: response.data.financeAccount,
      cdek_doc: response.data.cdek_doc,
      importer_delivery_period: response.data.importer_delivery_period,
      dealer_delivery_period: response.data.dealer_delivery_period,
    })
  },
  async createClientUser(_, userInfo) {
    return service.createClientUser(userInfo)
  },
  async fetchClientUsers({ commit }) {
    const clientUsers = await service.geClientUsers()
    const responce = clientUsers.data.data
    commit("SET_CLIENT_USERS", responce)
  },
  async deleteClientUser({ commit }, id) {
    return new Promise((resolve, reject) => {
      commit("DEL_CLIENT_USER_ITEM", id)
      service
        .delSingleClientUsers(id)
        .then(() => resolve())
        .catch(error => {
          console.log(error)
          reject()
        })
    })
  },
  async updateClientUser(_, user) {
    return new Promise((resolve, reject) => {
      service
        .updateClientUser(user.id, user)
        .then(() => resolve())
        .catch(() => {
          reject()
        })
    })
  },
}
export const getters = {
  columns(state) {
    return state.columns
  },
  columnsClientUsers(state) {
    return state.columnsClientUsers
  },
  clients(state) {
    return state.clients
  },
  filters(state) {
    return state.filters
  },
  createForm(state) {
    return state.createForm
  },
  data(state) {
    return state.createForm.contacts
  },
  contactNames(state) {
    return state.contactNames
  },
  paymentGroup(state) {
    return state.createForm.paymentGroup
  },
  clientUsers: state => state.clientUsers,
}
